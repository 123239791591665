import React, { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import zone from '../../utils/zone'

import Login from './Login'
import SignUp from './SignUp'
import ResetPassword from './ResetPassword'

export default observer(() => {
    const authTheme = useMemo(() => createMuiTheme(zone.theme()), [])

    return (
        <MuiThemeProvider theme={authTheme}>
            <CssBaseline />
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/sign-up" component={SignUp} />
                    <Route path="/reset-password" component={ResetPassword} />

                    <Route path="/">
                        <Redirect to="/login" />
                    </Route>
                </Switch>
            </BrowserRouter>
        </MuiThemeProvider>
    )
})
