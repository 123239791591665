import React, { useContext, useEffect, useMemo, Suspense } from 'react'
import { observer } from 'mobx-react-lite'
import { BrowserRouter, Switch } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme, fade, makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import MomentUtils from '@date-io/moment'

import zone from '../../utils/zone'

import i18n from '../../i18n'

import ManageOrganisations from './ManageOrganisations/ManageOrganisations'

import Navigation from './Navigation/Navigation'
import Loader from './Loader'

import AppState from './AppState'

import IntercomChat from './IntercomChat'

const useStyles = makeStyles(theme => ({
    app: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.default,
        '& *::selection': {
            background: fade(theme.palette.primary.main, 0.25),
        },
        // on mobile we have a column layout as there's no sidebar
        // but a top appbar which contains the drawer instead
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    appMain: {
        flexGrow: 1,
        overflow: 'scroll',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.10)',
        borderLeft: `1px solid ${theme.palette.divider}`,
        zIndex: 1,
        [theme.breakpoints.down('md')]: {
            // remove box shadow and borders of content on mobile
            boxShadow: 'none',
            borderLeft: `0px solid none`,
        },
    },
    appContentWrapper: {
        height: '100%',
        color: theme.palette.text.primary,
    },
    appContent: {
        height: '100%',
    },
}))

const ThemedApp = observer(({ appState, children }) => {
    const classes = useStyles()

    let appContent

    if (!appState.appOrganisation) {
        appContent = (
            <>
                <ManageOrganisations />
                <IntercomChat />
            </>
        )
    } else {
        appContent = (
            <>
                <Navigation />
                <main className={classes.appMain}>
                    <div className={classes.appContentWrapper}>
                        <div className={classes.appContent}>
                            <Suspense fallback={<Loader type="page" />}>
                                <Switch>{children}</Switch>
                            </Suspense>
                            <IntercomChat />
                        </div>
                    </div>
                </main>
            </>
        )
    }

    return (
        <div className={classes.app}>
            <BrowserRouter
                basename={appState.appOrganisation ? `/${appState.appOrganisation.organisation.id}` : ''}
            >
                <MuiPickersUtilsProvider utils={MomentUtils}>{appContent}</MuiPickersUtilsProvider>
            </BrowserRouter>
        </div>
    )
})

export default observer(properties => {
    const appState = useContext(AppState)

    const appTheme = useMemo(() => {
        const theme = zone.theme()

        return createMuiTheme(theme, i18n().mui)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n().mui])

    useEffect(() => {
        const { pathname } = window.location
        const organisationId = pathname.split('/')[1]

        appState.init(organisationId)

        return () => appState.release()
    }, [appState])

    return (
        <MuiThemeProvider theme={appTheme}>
            <CssBaseline />
            {appState.initialLoading && <Loader type="app" />}
            {!appState.initialLoading && <ThemedApp appState={appState} {...properties} />}
        </MuiThemeProvider>
    )
})
