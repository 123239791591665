import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'

import Toolbar from './Toolbar'
import Drawer from './Drawer'

const useStyles = makeStyles(theme => ({
    appBar: {
        flexGrow: 0,
        flexShrink: 0,
        overflow: 'hidden',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
}))

export default observer(({ navigationState }) => {
    const classes = useStyles()

    const [drawerOpen, setDrawerOpen] = useState(false)

    const handleToggleDrawer = () => {
        setDrawerOpen(value => !value)
    }

    return (
        <>
            <AppBar className={classes.appBar} elevation={0} position="static" color="inherit">
                <Toolbar onToggleDrawer={handleToggleDrawer} />
            </AppBar>
            <Drawer open={drawerOpen} onToggleDrawer={handleToggleDrawer} navigationState={navigationState} />
        </>
    )
})
