export default (value, isRequired, validationSettings) => {
    if (isRequired && (value === undefined || value === null || Number.isNaN(value))) {
        return 'required'
    }

    if (value !== null && value !== undefined && typeof value !== 'number') {
        return 'invalid_type'
    }

    if (typeof value === 'number') {
        if (typeof validationSettings.min === 'number' && value < validationSettings.min) {
            return 'number_min'
        }
        if (typeof validationSettings.max === 'number' && value > validationSettings.max) {
            return 'number_max'
        }
    }

    return null
}
