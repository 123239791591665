import React, { useRef, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import initials from 'initials'

import getRandomColor from '../utils/graphics/getRandomColor'

const useStyles = makeStyles(theme => ({
    avatar: props => ({
        position: 'relative',
        // backgroundColor: props.src ? 'white' : 'rgba(0,0,0,0.15)',
        borderRadius: props.square ? '25%' : '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        fontFamily: theme.typography.fontFamily,
        lineHeight: 1,
        overflow: 'hidden',
        userSelect: 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    }),
    overlay: props => ({
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,0.30)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: props.square ? '25%' : '100%',
        cursor: 'pointer',
        opacity: 0,
        '&:hover': {
            opacity: 0.75,
        },
        '&:active': {
            opacity: 1.0,
        },
    }),
    content: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const SelectOverlay = ({ className, onClick }) => {
    return (
        <div onClick={onClick} className={className}>
            <svg width="24" height="24">
                <path
                    d="M21,0H3A1,1,0,0,0,2,1V23a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V1A1,1,0,0,0,21,0ZM16,12.414l-3-3V17H11V9.414l-3,3L6.586,11l4.707-4.707a1,1,0,0,1,1.414,0L17.414,11Z"
                    fill="white"
                />
            </svg>
        </div>
    )
}

export default React.forwardRef(
    ({ name, src, size, square, onSelect, className, style, children, ...otherProps }, ref) => {
        const classes = useStyles({ square, src })

        const fileInput = useRef(null)

        const handleSelectAvatar = () => {
            if (fileInput.current) {
                fileInput.current.click()
            }
        }

        const handleFileChange = evt => {
            const files = evt.target.files
            if (files && files.length) {
                onSelect(evt.target.files[0])
            }
        }

        useImperativeHandle(ref, () => ({
            selectAvatar: handleSelectAvatar,
        }))

        const avatarStyle = { ...style }
        const avatarSize = size || 40

        avatarStyle.width = avatarSize
        avatarStyle.height = avatarSize
        avatarStyle.fontSize = Math.floor(avatarSize / 2.5)

        if (!src) {
            if (name) {
                avatarStyle.backgroundColor = getRandomColor(name)
                avatarStyle.color = 'white'
            }
        } else {
            avatarStyle.backgroundImage = `url("${src}")`
        }

        return (
            <div className={classNames(className, classes.avatar)} style={avatarStyle} {...otherProps}>
                {!src && !children && initials((name || '').toUpperCase()).substr(0, 2)}
                {children && <div className={classes.content}>{children}</div>}
                {!!onSelect && (
                    <>
                        <input
                            onChange={handleFileChange}
                            ref={fileInput}
                            type="file"
                            style={{ display: 'none', opacity: '0' }}
                        />
                        <SelectOverlay className={classes.overlay} onClick={handleSelectAvatar} />
                    </>
                )}
            </div>
        )
    }
)
