import { observable, computed, action, decorate } from 'mobx'

import Model from './Model'

const decoratedModels = []

export default function decorateModel(modelClass) {
    // If already decorated leave early
    if (decoratedModels.indexOf(modelClass) >= 0) {
        return modelClass
    }

    decoratedModels.push(modelClass)

    const Fields = modelClass.Fields
    if (!Fields) {
        console.warn(`Model ${modelClass.name} has no Fields, is this by intention?`)
        return modelClass
    }

    const decoratorProps = {}

    // Decorate static fields as observables
    for (const fieldName in Fields) {
        if (!Object.prototype.hasOwnProperty.call(Fields, fieldName)) {
            continue
        }

        const fieldInfo = Fields[fieldName]
        const fieldType = Array.isArray(fieldInfo.type) ? fieldInfo.type[0] : fieldInfo.type
        const isComputedProperty = fieldInfo.get || false

        if (!fieldType) {
            throw new Error(`Missing type on field ${fieldName} declared in ${modelClass.name}`)
        }

        const propDesc = Object.getOwnPropertyDescriptor(modelClass.prototype, fieldName)
        if (isComputedProperty && (!propDesc || !propDesc.get)) {
            throw new Error(`${modelClass.name} already is missing a property getter for field ${fieldName}`)
        } else if (!isComputedProperty && propDesc) {
            throw new Error(`${modelClass.name} already has a property for field ${fieldName}`)
        }

        if (Model.isSubModel(fieldType)) {
            decorateModel(fieldType)
        }

        // leave for computed properties as they will be decorated later on
        if (isComputedProperty) {
            continue
        }

        // all other fields become observables
        decoratorProps[fieldName] = observable
    }

    // Decorate dynamic getters as computed and action functions as actions
    const propertyDescriptors = Object.getOwnPropertyDescriptors(modelClass.prototype)
    if (propertyDescriptors) {
        for (const property in propertyDescriptors) {
            if (!Object.prototype.hasOwnProperty.call(propertyDescriptors, property)) {
                continue
            }

            const propertyDesc = propertyDescriptors[property]
            if (propertyDesc.get) {
                decoratorProps[property] = computed
            } else if (property.endsWith('Action')) {
                decoratorProps[property] = action
            }
        }
    }

    decorate(modelClass, decoratorProps)

    return modelClass
}
