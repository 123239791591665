import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import Intercom from 'react-intercom'

import zone from '../../utils/zone'

import AppState from './AppState'

export default observer(() => {
    const appState = useContext(AppState)
    const { userData, appOrganisation } = appState

    if (!zone.intercom) {
        return null
    }

    const intercomProps = {
        /** intercom settings */
        hide_default_launcher: !!appOrganisation,
        /** user */
        created_at: new Date(userData.createdAt).getTime() / 1000,
        user_id: userData.id,
        email: userData.email,
        name: userData.name || userData.email,
        zone: zone.key,
    }

    if (appOrganisation) {
        const { organisation } = appOrganisation

        intercomProps.company = {
            id: organisation.id,
            name: organisation.name,
            remote_created_at: organisation.createdAt,
            size: 999 /* TODO */,
            plan: 'ToBeDone' /* TODO */,
            monthly_spend: 133 /* TODO */,
            website: `${window.location.origin}/${organisation.id}`,
            type: organisation.type,
            country: organisation.country,
            zone: zone.key,
        }
    }

    return <Intercom appID={zone.intercom.appId} {...intercomProps} />
})
