import data from './data.json'

import i18n from '../../i18n'

const getCountryName = countryCode => {
    const theCountry = data[countryCode]
    if (!theCountry) {
        throw new Error(`Unknown country-code ${countryCode}`)
    }
    return theCountry.name[i18n.getLocale()] || theCountry.name.de || theCountry.name.en
}

const getCountryCode = countryName => {
    for (const countryCode in data) {
        const theName = getCountryName(countryCode)
        if (theName.toLowerCase() === countryName.toLowerCase()) {
            return countryCode
        }
    }
    return null
}

export default {
    ...data,
    codes: Object.keys(data),
    getCountryName,
    getCountryCode,
}
