import React, { useContext } from 'react'
import { observer, useLocalStore } from 'mobx-react-lite'
import Hidden from '@material-ui/core/Hidden'

import AppState from '../AppState'

import NavigationState from './NavigationState'
import Mobile from './Mobile/Mobile'
import Sidebar from './Sidebar/Sidebar'

export default observer(() => {
    const appState = useContext(AppState)

    const navigationState = useLocalStore(NavigationState, { appState })

    return (
        <>
            <Hidden lgUp>
                <Mobile navigationState={navigationState} />
            </Hidden>
            <Hidden mdDown>
                <Sidebar navigationState={navigationState} />
            </Hidden>
        </>
    )
})
