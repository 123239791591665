/* eslint-disable max-classes-per-file */
import uuidv4 from 'uuid/v4'
import Model from './Model'

import i18n from '../i18n'

import zone from '../utils/zone'
import countries from '../utils/countries'
import Stitch from '../utils/Stitch'

class OrganisationAvatar extends Model.Resource {
    constructor() {
        super({
            image: {
                maxWidth: 512,
                maxHeight: 512,
            },
        })
    }
}

class Organisation extends Model {
    static CollectionName = 'organisations'

    static Permissions = {
        read: true,
        write: 'organisation.edit',
        delete: 'organisation.edit',
        insert: 'everyone',
    }

    static Fields = {
        lastAccessedAt: {
            type: Date,
        },
        lastAccessedBy: {
            type: String,
        },
        country: {
            type: String,
            required: true,
            enum: countries.codes,
        },
        avatar: {
            type: OrganisationAvatar,
        },
        owner: {
            type: Stitch.BSON.ObjectId,
        },
        name: {
            type: String,
            required: true,
        },
    }

    // Accept an invitation on behalf of the current user
    static async acceptInvitation(invitationToken) {
        const UserData = require('./UserData').default

        const userData = new UserData()
        if (!(await userData.load({ userId: Stitch.userId }))) {
            throw new Error('Unable to load user data for current user.')
        }

        return Stitch.call('organisationAcceptInvitation', invitationToken, userData._id)
    }

    constructor(data) {
        super(Organisation.Fields, data)
    }

    get resourcePath() {
        return `organisation/${this.id}/organisation`
    }

    // Create a new org on behalf of the currrent user
    async create() {
        const UserData = require('./UserData').default

        const userData = new UserData()

        if (!(await userData.load({ userId: Stitch.userId }))) {
            throw new Error('Unable to load user data for current user.')
        }

        this.owner = Stitch.userId

        if (!(await this.save())) {
            return false
        }

        await Stitch.client.auth.refreshCustomData()

        return true
    }

    // Invite another user into an organisation on behalf of the current user
    async inviteUser(email, owner, scopes) {
        const OrganisationUser = require('./OrganisationUser').default

        const organisationUser = new OrganisationUser({
            organisation: this,
            email,
            invitationToken: uuidv4().replace(/-/g, ''),
            owner,
            scopes: scopes || [],
        })

        if (!(await organisationUser.save())) {
            throw new Error('Unable to create organisation owner user.')
        }

        const sentMail = await Stitch.sendEMail(
            email,
            i18n().mail.inviteUser.subject({ organisationName: this.name }),
            i18n().mail.inviteUser.text({
                organisationName: this.name,
                appName: zone.name,
                invitationLink: organisationUser.invitationLink,
            })
        )

        return { organisationUser, sentMail }
    }
}

export default Model.declare(Organisation)
