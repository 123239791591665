import moment from 'moment'
import stringSearchTerm from './stringSearchTerm'

const NoValueComparators = [
    'list.empty',
    'list.notEmpty',
    'string.exists',
    'string.notExists',
    'number.exists',
    'number.notExists',
    'date.exists',
    'date.notExists',
    'bool.isTrue',
    'bool.isFalse',
    'bool.exists',
    'bool.notExists',
]

const compareRequiresValue = compare => {
    return !NoValueComparators.includes(compare)
}

const normalizeStringValue = (value, caseSensitive) => {
    if (typeof value !== 'string' || caseSensitive) {
        return value
    }

    if (value === '') {
        return value
    }

    return stringSearchTerm(value, 'like')
}

const convertItem = ({ field, compare, value, caseSensitive }) => {
    if (
        !compare ||
        (compare && (value === undefined || value === null || value === '') && compareRequiresValue(compare))
    ) {
        return null
    }

    switch (compare) {
        // -- list
        case 'list.contains':
            return { [field]: typeof value === 'string' ? normalizeStringValue(value, caseSensitive) : value }
        case 'list.notContains':
            return {
                [field]: {
                    $not: typeof value === 'string' ? normalizeStringValue(value, caseSensitive) : value,
                },
            }
        case 'list.empty':
            return { $or: [{ [field]: { $size: 0 } }, { [field]: { $exists: false } }, { [field]: null }] }
        case 'list.notEmpty':
            return { $and: [{ [field]: { $not: { $size: 0 } } }, { [field]: { $exists: true } }] }
        // -- string
        case 'string.equals':
            return { [field]: normalizeStringValue(value, caseSensitive) }
        case 'string.notEquals':
            return { [field]: { $not: normalizeStringValue(value, caseSensitive) } }
        case 'string.contains':
        case 'string.startsWith':
        case 'string.endsWith':
            return { [field]: stringSearchTerm(value, compare.split('.')[1]) }
        case 'string.notContains':
            return { [field]: { $not: stringSearchTerm(value, 'contains') } }
        case 'string.exists':
            return {
                $and: [{ [field]: { $exists: true } }, { [field]: { $ne: null } }, { [field]: { $ne: '' } }],
            }
        case 'string.notExists':
            return { $or: [{ [field]: { $exists: false } }, { [field]: null }, { [field]: '' }] }
        // -- number
        case 'number.equals':
            return { [field]: value }
        case 'number.notEquals':
            return { [field]: { $ne: value } }
        case 'number.greaterThan':
            return { [field]: { $gt: value } }
        case 'number.lessThan':
            return { [field]: { $lt: value } }
        case 'number.exists':
            return { $and: [{ [field]: { $exists: true } }, { [field]: { $ne: null } }] }
        case 'number.notExists':
            return { $or: [{ [field]: { $exists: false } }, { [field]: null }] }
        // -- date
        case 'date.moreThanDaysAgo':
            return {
                [field]: {
                    $lt: moment()
                        .subtract(value, 'days')
                        .endOf('day')
                        .toDate(),
                },
            }
        case 'date.lessThanDaysAgo':
            return {
                [field]: {
                    $gte: moment()
                        .subtract(value, 'days')
                        .startOf('day')
                        .toDate(),
                },
            }
        case 'date.moment':
            switch (value) {
                case 'beforeToday':
                    return {
                        [field]: {
                            $lt: moment()
                                .startOf('day')
                                .toDate(),
                        },
                    }
                case 'beforeOrAtToday':
                    return {
                        [field]: {
                            $lt: moment()
                                .endOf('day')
                                .toDate(),
                        },
                    }
                case 'afterToday':
                    return {
                        [field]: {
                            $gte: moment()
                                .add(1, 'day')
                                .startOf('day')
                                .toDate(),
                        },
                    }
                case 'afterOrAtToday':
                    return {
                        [field]: {
                            $gte: moment()
                                .startOf('day')
                                .toDate(),
                        },
                    }
                default:
                    throw new Error(`Unknown value "${value} for date.moment operator.`)
            }
        case 'date.after':
            return {
                [field]: {
                    $gte: moment(value)
                        .endOf('day')
                        .toDate(),
                },
            }
        case 'date.before':
            return {
                [field]: {
                    $lt: moment(value)
                        .startOf('day')
                        .toDate(),
                },
            }
        case 'date.equals':
            return {
                [field]: {
                    $gte: moment(value)
                        .startOf('day')
                        .toDate(),
                    $lt: moment(value)
                        .endOf('day')
                        .toDate(),
                },
            }
        case 'date.exists':
            return { $and: [{ [field]: { $exists: true } }, { [field]: { $ne: null } }] }
        case 'date.notExists':
            return { $or: [{ [field]: { $exists: false } }, { [field]: null }] }
        // -- bool
        case 'bool.isTrue':
            return { [field]: true }
        case 'bool.isFalse':
            return { [field]: false }
        case 'bool.exists':
            return { $or: [{ [field]: true }, { [field]: false }] }
        case 'bool.notExists':
            return { $and: [{ [field]: { $ne: true } }, { [field]: { $ne: false } }] }
        default:
            throw new Error(`Unknown comparator "${compare}" for field "${field}".`)
    }
}

const convertGroup = filterGroup => {
    if (!filterGroup.fields || !filterGroup.fields.length) {
        return null
    }

    const convertedItems = filterGroup.fields.map(convertItem).filter(x => !!x)
    if (convertedItems.length === 0) {
        return null
    }

    if (convertedItems.length === 1) {
        return convertedItems[0]
    }

    return {
        [`$${filterGroup.operator}`]: convertedItems,
    }
}

function convertFilterToQuery(filter) {
    if (!filter || !filter.groups) {
        return {}
    }

    const convertedGroups = (filter.groups || []).map(convertGroup).filter(x => !!x)
    if (!convertedGroups.length) {
        return {}
    }

    if (convertedGroups.length === 1) {
        return convertedGroups[0]
    }

    return {
        [`$${filter.operator}`]: convertedGroups,
    }
}

convertFilterToQuery.compareRequiresValue = compareRequiresValue

export default convertFilterToQuery
