import isEmail from 'validator/lib/isEmail'
import isMobilePhone from 'validator/lib/isMobilePhone'

export default (value, isRequired, validationSettings) => {
    if (isRequired && !value) {
        return 'required'
    }

    if (value !== null && value !== undefined && typeof value !== 'string') {
        return 'invalid_type'
    }

    // if there's no value given we're done here
    if (!value) {
        return null
    }

    if (validationSettings.enum) {
        if (!validationSettings.enum.includes(value)) {
            return 'invalid_enum'
        }
    }

    if (validationSettings.format) {
        switch (validationSettings.format) {
            case 'email':
                if (!isEmail(value)) {
                    return 'invalid_email'
                }
                break
            case 'mobilePhone':
                if (!isMobilePhone(value)) {
                    return 'invalid_mobile'
                }
                break
            default:
                throw new Error(`Invalid string format ${validationSettings.format}`)
        }
    }

    return null
}
