import Icon from '../../../assets/brands/campai/icon.svg'
import FavIcon from '../../../assets/brands/campai/favicon.png'

import Theme from './Theme'

export default {
    key: 'campai',
    theme: () => Theme({}),
    name: 'campai · one',
    website: 'https://www.campai.com',
    mailerTestAddress: 'test@campai.com',
    mailerFromAddress: 'campai one <no-reply@mail.campai.com>',
    vendor: 'campai GmbH',
    vendorImprint:
        'campai GmbH | Sitz der Gesellschaft: Berlin | Registergericht: Amtsgericht Berlin (Charlottenburg), HRB 192374 | Geschäftsführer: Alexander Adam',
    icon: Icon,
    favicon: FavIcon,
    countries: ['DE', 'AT'],
    defaultCountry: 'DE',
    canny: {
        appId: '5e3291e93fab13568fcec579',
        link: 'https://feedback.campai.com',
    },
    intercom: {
        appId: process.env.NODE_ENV === 'production' ? 'gf78ibvf' : 'yi75zzqp',
    },
    help: 'https://hilfecenter.campai.com',
}
