/* eslint-disable guard-for-in */
import { observable, decorate, isComputedProp, isObservableProp, toJS } from 'mobx'
import equal from 'fast-deep-equal'
import flat from 'flat'

import Stitch from '../../utils/Stitch'

import { aquireWatcher, releaseWatcher } from './watchers'
import convertUpdateDocument from './convertUpdateDocument'
import ValidationError from './ValidationError'
import validate from './validate'

const isSecurityRuleError = error => {
    return (
        error.message === 'update not permitted' ||
        error.message === 'insert not permitted' ||
        error.message === 'delete not permitted' ||
        error.errorCodeName === 'NoMatchingRuleFound'
    )
}

export default class Model {
    static id = idOrModelOrString => {
        if (!idOrModelOrString) {
            throw new Error('Invalid idOrModelOrString parameter.')
        }
        if (typeof idOrModelOrString === 'string') {
            return new Stitch.BSON.ObjectId(idOrModelOrString)
        }
        if (idOrModelOrString._id) {
            return new Stitch.BSON.ObjectId(idOrModelOrString._id.toString())
        }
        return new Stitch.BSON.ObjectId(idOrModelOrString.toString())
    }

    static isRootModel(classOrInstance) {
        if (!classOrInstance) {
            return false
        }

        return (
            (classOrInstance instanceof Model || classOrInstance.prototype instanceof Model) &&
            !Model.isSubModel(classOrInstance)
        )
    }

    static isSubModel(classOrInstance) {
        if (!classOrInstance) {
            return false
        }

        if (classOrInstance instanceof Model) {
            return !classOrInstance.constructor.CollectionName
        }

        if (classOrInstance.prototype instanceof Model) {
            return !classOrInstance.CollectionName
        }

        return false
    }

    static Fields = {
        _id: {
            type: Stitch.BSON.ObjectId,
        },
        createdAt: {
            type: Date,
            default: () => new Date(),
        },
        createdBy: {
            type: String,
            default: () => Stitch.userId,
        },
        updatedAt: {
            type: Date,
        },
        updatedBy: {
            type: String,
        },
        removedAt: {
            type: Date,
        },
        removedBy: {
            type: String,
        },
    }

    fields

    data

    _watcher = null

    constructor(fields, data) {
        this.fields = { ...fields, ...Model.Fields }
        this._instantiateModel()

        if (data) {
            if (data instanceof Model) {
                this.deserialize(data.serialize())
            } else {
                this.deserialize(data)
            }
        }
    }

    get id() {
        return this._id ? this._id.toString() : null
    }

    get collection() {
        return Stitch.db.collection(this.constructor.CollectionName)
    }

    get isValid() {
        try {
            this.validate()
            return true
        } catch (e) {
            if (e instanceof ValidationError) {
                return false
            }

            throw e
        }
    }

    clone() {
        return new this.constructor(this)
    }

    validate(parentPath = '', returnErrors = false) {
        let errors = []

        const getPath = path => {
            if (parentPath) {
                return `${parentPath}.${path}`
            }
            return path
        }

        const isRequired = required => {
            if (typeof required === 'function') {
                return required(this)
            }
            return required || false
        }

        const validateValue = (type, settings, value, path) => {
            const required = isRequired(settings.required)

            if (Model.isSubModel(type)) {
                const valErrors = value.validate(getPath(path), true)
                if (valErrors) {
                    errors = errors.concat(valErrors)
                }
            } else if (Model.isRootModel(type)) {
                if (required && (!value || (value instanceof Model && !value._id))) {
                    errors.push({ path: getPath(path), type: 'required' })
                } else if (value instanceof Model) {
                    // If there's an instance but without an id warn in dev as that is usually not intended
                    if (process.env.NODE_ENV === 'development' && !value._id) {
                        console.warn(
                            `Trying to save "${this.constructor.name}" on field "${getPath(
                                path
                            )}" with a model reference that is not saved or has no id and thus will be saved as null. Is this by intention?`
                        )
                    }

                    const ModelClass = type
                    if (!(value instanceof ModelClass)) {
                        errors.push({ path: getPath(path), type: 'wrong_model' })
                    }
                }
            } else {
                const error = validate(type, value, required, settings)
                if (error) {
                    errors.push({ path: getPath(path), type: error })
                }
            }
        }

        for (const fieldName in this.fields) {
            const fieldInfo = this.fields[fieldName]
            const fieldValue = this[fieldName]
            const isArray = Array.isArray(fieldInfo.type)

            if (isArray) {
                if (!Array.isArray(fieldValue)) {
                    errors.push({ path: getPath(fieldName), type: 'invalid_type' })
                } else {
                    if (isRequired(fieldInfo.required) && (!fieldValue || !fieldValue.length)) {
                        errors.push({ path: getPath(fieldName), type: 'required' })
                    }

                    if (fieldValue) {
                        fieldValue.forEach((subValue, subIndex) => {
                            validateValue(fieldInfo.type[0], fieldInfo, subValue, `${fieldName}.${subIndex}`)
                        })
                    }
                }
            } else {
                validateValue(fieldInfo.type, fieldInfo, this[fieldName], fieldName)
            }

            if (fieldInfo.validate && typeof fieldInfo.validate === 'function') {
                const error = fieldInfo.validate(this)
                if (error) {
                    errors.push({ path: fieldName, type: error })
                }
            }
        }

        if (errors.length) {
            if (returnErrors) {
                return errors
            }

            throw new ValidationError(errors)
        }

        return null
    }

    async load(queryOrId, { allowRemoved = false } = {}, ignoreTestUUID = false) {
        if (!this.constructor.CollectionName) {
            throw new Error(`Missing collection on ${this.constructor.name}`)
        }

        if (!queryOrId) {
            queryOrId = this._id
        }

        if (!queryOrId) {
            throw new Error('No ID for model to load from.')
        }

        let query
        if (typeof queryOrId === 'string' || queryOrId instanceof Stitch.BSON.ObjectId) {
            query = { _id: Model.id(queryOrId) }
        } else {
            query = queryOrId

            if (process.env.NODE_ENV === 'test') {
                if (!ignoreTestUUID) query = { ...query, __testUUID: process.env.MODEL_TEST_UUID }
            }
        }

        // Avoid loading soft deleted models if desired
        if (!allowRemoved) {
            query = { ...query, removedAt: { $exists: false } }
        }

        const document = await this.collection.findOne(query)

        if (document) {
            return this.deserialize(document, true)
        }

        return false
    }

    async watch() {
        if (!this._id) {
            throw new Error(`Invalid document to watch for on ${this.constructor.name}. Requires an _id.`)
        }

        if (this._watcher) {
            throw new Error(`Already installed a watcher on ${this.constructor.name}.`)
        }

        this._watcher = await aquireWatcher(this.collection, [this._id], (operation, document) => {
            if (operation === 'update') {
                this.deserialize(document, true)
            }
        })

        return true
    }

    async unwatch() {
        if (this._watcher) {
            releaseWatcher(this._watcher)
            this._watcher = null
        }
    }

    async patch() {
        if (Model.isSubModel(this)) {
            throw new Error('Patching is only supported on root models')
        }

        const isNewDocument = !this._id
        if (isNewDocument) {
            const result = await this.save()

            if (!result) {
                return false
            }

            this.data = this.serialize({ ignoreModelFields: true })
            return flat(this.data, { safe: true })
        }

        // Validate now which throws if invalid
        this.validate()

        const currentData = this.serialize({ ignoreModelFields: true })
        const currentFlatData = flat(currentData, { safe: true })
        const previousFlatData = flat(this.data, { safe: true })

        const changeSet = {}
        let hasChanges = false

        Object.keys(currentFlatData)
            .concat(Object.keys(previousFlatData))
            .filter((value, index, self) => self.indexOf(value) === index)
            .forEach(property => {
                if (!equal(currentFlatData[property], previousFlatData[property])) {
                    // TODO : We could improve here by comparing arrays as separate patches
                    // as well (add/remove/move) as for now we just restore them completely.
                    changeSet[property] = currentFlatData[property]
                    hasChanges = true
                }
            })

        if (!hasChanges) {
            // No changes so return true as there's nothing to do
            return true
        }

        const result = await this.update(changeSet, false, true)
        if (result) {
            this.data = currentData
            return changeSet
        }

        return false
    }

    async save() {
        if (Model.isSubModel(this)) {
            throw new Error('Saving is only supported on root models')
        }

        // Validate now which throws if invalid
        this.validate()

        const getSerializedDocument = () => {
            const document = this.serialize({})

            // Ugly hack - in test mode we add an "invisible" uuid to the documents
            // so we hopefully don't clanch with other tests running in parallel
            if (process.env.NODE_ENV === 'test') {
                document.__testUUID = process.env.MODEL_TEST_UUID
            }

            return document
        }

        const isNewDocument = !this._id

        try {
            // If there's no id yet, upsert document first to ensure we receive a valid id
            // before storing as otherwise we might not be able to correctly store resources
            if (isNewDocument) {
                // we need to store the whole document even when we update later on
                // as otherwise some security rules may fail
                const document = getSerializedDocument()
                const insertResult = await this.collection.insertOne(document)

                if (!insertResult.insertedId) {
                    return false
                }

                this._id = new Stitch.BSON.ObjectId(insertResult.insertedId)
            }

            try {
                // Collect and store all resources first if any
                const resources = this.resources.filter(resource => resource.isStorable)

                // If we have a new document and no resource to store we leave here
                // as no update is necessary at all
                if (isNewDocument && !resources.length) {
                    return true
                }

                if (resources.length) {
                    const resourcePath = this.resourcePath
                    const resourcesStored = await Promise.all(
                        resources.map(resource => resource.store(resourcePath))
                    )

                    for (const res of resourcesStored) {
                        if (!res) {
                            throw new Error('Unable to store a resource.')
                        }
                    }
                }

                // Setup our updatedAt and updatedBy if this is not a new document
                if (!isNewDocument) {
                    this._getAndSetUpdateFields()
                }

                // Finally re-serialize our current state again store it
                const document = getSerializedDocument()

                const updateResult = await this.collection.updateOne({ _id: this._id }, document)

                return updateResult && updateResult.modifiedCount === 1
            } catch (innerError) {
                // If we had a newly document inserted, make sure to delete it in case of failure
                // and then we can throw further to let the caller handle the issue
                if (isNewDocument && this._id) {
                    await this.collection.deleteOne({ _id: this._id })
                }

                // Throw one up
                throw innerError
            }
        } catch (outerError) {
            if (isSecurityRuleError(outerError)) {
                console.error(outerError)
                return false
            }

            throw outerError
        }
    }

    async update(updateData, ignoreUpdateFields = false, preventReload = false) {
        if (!this._id) {
            throw new Error('Update can only be executed on existing documents.')
        }

        const updateDocument = convertUpdateDocument(updateData)
        if (!Object.keys(updateDocument).length) {
            // nothing to update so return immediately
            return true
        }

        // Append our update information to the update set if desired
        if (!ignoreUpdateFields) {
            updateDocument.$set = {
                ...updateDocument.$set,
                ...this._getAndSetUpdateFields(),
            }
        }

        try {
            const result = await this.collection.updateOne({ _id: this._id }, updateDocument, {
                upsert: false,
            })

            if (result && result.modifiedCount === 1) {
                if (!preventReload) {
                    await this.load()
                }
                return true
            }

            return false
        } catch (error) {
            if (isSecurityRuleError(error)) {
                console.error(error)
                return false
            }

            throw error
        }
    }

    async remove() {
        if (!this._id) {
            throw new Error(`Invalid document to remove on ${this.constructor.name}. Requires an _id.`)
        }

        try {
            // Remove ourself now
            const removeResult = await this.collection.updateOne(
                { _id: this._id },
                { $set: { removedAt: new Date(), removedBy: Stitch.userId } },
                { upsert: false }
            )

            return removeResult && removeResult.modifiedCount === 1
        } catch (error) {
            if (isSecurityRuleError(error)) {
                console.error(error)
                return false
            }

            throw error
        }
    }

    async delete() {
        if (!this._id) {
            throw new Error(`Invalid document to delete on ${this.constructor.name}. Requires an _id.`)
        }

        // Collect and delete all resources first if any
        const resources = this.resources
        if (resources.length) {
            await Promise.all(resources.map(resource => resource.delete()))
        }

        try {
            // Delete ourself now
            const deleteResult = await this.collection.deleteOne({ _id: this._id })

            return deleteResult && deleteResult.deletedCount === 1
        } catch (error) {
            if (isSecurityRuleError(error)) {
                console.error(error)
                return false
            }

            throw error
        }
    }

    async populate(...fieldNames) {
        const populateField = async fieldName => {
            const fieldInfo = this.fields[fieldName]
            if (!fieldInfo) {
                throw new Error(`Trying to populate an unknown field "${fieldName}.`)
            }

            const fieldType = Array.isArray(fieldInfo.type) ? fieldInfo.type[0] : fieldInfo.type
            if (!Model.isRootModel(fieldType)) {
                throw new Error(
                    `Field "${fieldName} is not a valid root model and such can not be populated.`
                )
            }

            const fieldValue = this[fieldName]
            if (!fieldValue) {
                // -- done, nothing to do there
                return true
            }

            const populateModel = async idOrModel => {
                if (idOrModel instanceof Stitch.BSON.ObjectId) {
                    const ModelClass = fieldType
                    const modelInstance = new ModelClass(null, this, fieldName)
                    await modelInstance.load(idOrModel)
                    return modelInstance
                }
                return idOrModel
            }

            if (Array.isArray(fieldValue)) {
                this[fieldName] = await Promise.all(fieldValue.map(populateModel))
            } else {
                this[fieldName] = await populateModel(fieldValue)
            }

            return true
        }

        const result = await Promise.all(fieldNames.map(populateField))

        for (const res of result) {
            if (res !== true) {
                return false
            }
        }

        return true
    }

    serialize({ ignoreModelFields = false } = {}) {
        const document = {}

        const serializeValue = (type, value) => {
            if (Model.isSubModel(value)) {
                // We always ignore model fields on sub models
                return value.serialize({ ignoreModelFields: true })
            }

            if (Model.isRootModel(value)) {
                return value && value._id ? value._id : value
            }

            if (typeof value === 'object') {
                return toJS(value)
            }

            return value
        }

        for (const fieldName in this.fields) {
            // Ignore model properties if desired
            if (ignoreModelFields) {
                if (Object.prototype.hasOwnProperty.call(Model.Fields, fieldName)) {
                    continue
                }
            }

            // Ignore ids on submodels
            if (fieldName === '_id' && Model.isSubModel(this)) {
                continue
            }

            const fieldInfo = this.fields[fieldName]
            const fieldValue = this[fieldName]

            if (Array.isArray(fieldInfo.type)) {
                document[fieldName] = (fieldValue || []).map(value => {
                    return serializeValue(fieldInfo.type[0], value)
                })
            } else if (fieldValue !== undefined) {
                document[fieldName] = serializeValue(fieldInfo.type, fieldValue)
            }
        }

        return document
    }

    deserialize(document, showWarnings = false) {
        this.reset()

        for (const fieldName in this.fields) {
            // Ignore computed properties
            if (isComputedProp(this, fieldName)) {
                continue
            }

            if (!Object.prototype.hasOwnProperty.call(document, fieldName)) {
                continue
            }

            const propertyValue = document[fieldName]

            const fieldInfo = this.fields[fieldName]

            if (Array.isArray(fieldInfo.type)) {
                this[fieldName] = (propertyValue || []).map(value => {
                    if (Model.isSubModel(fieldInfo.type[0])) {
                        const ModelClass = fieldInfo.type[0]
                        const classInstance = new ModelClass(null, this, fieldName)
                        classInstance.deserialize(value)
                        return classInstance
                    }
                    return value
                })
            } else if (Model.isSubModel(fieldInfo.type)) {
                this[fieldName].deserialize(propertyValue)
            } else if (propertyValue !== undefined) {
                this[fieldName] = propertyValue
            }
        }

        if (process.env.NODE_ENV === 'development' && showWarnings) {
            const missingFieldsOnTarget = []
            const missingFieldsOnSource = []

            for (const prop in document) {
                if (!Object.prototype.hasOwnProperty.call(this.fields, prop)) {
                    missingFieldsOnTarget.push(prop)
                }
            }

            for (const fieldName in this.fields) {
                // Ignore ids on submodels
                if (fieldName === '_id' && Model.isSubModel(this)) {
                    continue
                }

                if (!Object.prototype.hasOwnProperty.call(document, fieldName)) {
                    missingFieldsOnSource.push(fieldName)
                }
            }

            if (missingFieldsOnTarget.length) {
                console.warn(
                    `Missing fields ${missingFieldsOnTarget
                        .map(prop => `${prop}=${document[prop]}`)
                        .join(', ')} in ${this.constructor.name}. Is this by intention?`
                )
            }

            if (missingFieldsOnSource.length) {
                console.warn(
                    `No properties for fields ${missingFieldsOnSource.join(', ')} in ${
                        this.constructor.name
                    } found in source document. Will all be set to their defaults. Is this by intention?`
                )
            }
        }

        this.data = this.serialize({ ignoreModelFields: true })

        return true
    }

    get resourcePath() {
        throw new Error('Not implemented.')
    }

    get resources() {
        let resources = []

        for (const fieldName in this.fields) {
            const fieldInfo = this.fields[fieldName]
            const fieldType = Array.isArray(fieldInfo.type) ? fieldInfo.type[0] : fieldInfo.type

            if (!this[fieldName]) {
                continue
            }

            if (Model.isSubModel(fieldType) && !fieldType.IS_RESOURCE) {
                if (Array.isArray(fieldInfo.type)) {
                    resources = resources.concat(this[fieldName].map(subModel => subModel.resources).flat())
                } else {
                    resources = resources.concat(this[fieldName].resources)
                }
            } else if (fieldType.IS_RESOURCE) {
                if (Array.isArray(fieldInfo.type)) {
                    resources = resources.concat(this[fieldName])
                } else {
                    resources.push(this[fieldName])
                }
            }
        }

        return resources
    }

    reset() {
        for (const fieldName in this.fields) {
            // Ignore computed properties
            if (isComputedProp(this, fieldName)) {
                continue
            }

            const fieldInfo = this.fields[fieldName]

            if (fieldInfo.default !== undefined) {
                this[fieldName] =
                    typeof fieldInfo.default === 'function' ? fieldInfo.default() : fieldInfo.default
            } else if (Array.isArray(fieldInfo.type)) {
                this[fieldName] = []
            } else if (Model.isSubModel(fieldInfo.type)) {
                this[fieldName].reset()
            } else if (!['removedAt', 'removedBy'].includes(fieldName)) {
                this[fieldName] = null
            }
        }

        this.data = this.serialize({ ignoreModelFields: true })
    }

    _instantiateModel() {
        for (const fieldName in this.fields) {
            // Ignore computed properties
            if (isComputedProp(this, fieldName)) {
                continue
            }

            // Bail if trying to access non-computed properties
            if (!isObservableProp(this, fieldName)) {
                throw new Error(
                    `None observable field ${fieldName} on ${this.constructor.name}. Did you forget to call decorateModel?`
                )
            }

            const fieldInfo = this.fields[fieldName]

            if (Array.isArray(fieldInfo.type)) {
                this[fieldName] = []
            } else if (Model.isSubModel(fieldInfo.type)) {
                const ModelClass = fieldInfo.type
                const classInstance = new ModelClass(null, this, fieldName)
                this[`_${fieldName}`] = classInstance
                Object.defineProperty(this, fieldName, {
                    get() {
                        return this[`_${fieldName}`]
                    },
                    set() {
                        throw new Error(`Sub model ${fieldName} on ${this.constructor.name} is read-only`)
                    },
                })
            }
        }

        // Reset everything to their default values for initial data
        this.reset()
    }

    _getAndSetUpdateFields() {
        this.updatedAt = new Date()
        this.updatedBy = Stitch.userId

        return {
            updatedAt: this.updatedAt,
            updatedBy: this.updatedBy,
        }
    }
}

decorate(Model, {
    _id: observable,
    createdAt: observable,
    createdBy: observable,
    updatedAt: observable,
    updatedBy: observable,
    removedAt: observable,
    removedBy: observable,
})
