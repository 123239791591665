export default (value, isRequired) => {
    if (isRequired && !value) {
        return 'required'
    }

    if (value !== null && value !== undefined && typeof value !== 'object') {
        return 'invalid_type'
    }

    return null
}
