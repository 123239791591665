import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

import Link from './Link'

const useStyles = makeStyles(theme => ({
    button: {
        position: 'relative',
    },
    link: {
        backgroundColor: 'transparent !important',
        color: theme.palette.primary.dark,
        textTransform: 'none',
        padding: 0,
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    progress: {
        position: 'absolute',
        left: 0,
        bottom: -1,
        right: 0,
        height: 3,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
}))

export default React.forwardRef(
    (
        {
            loading,
            variant,
            color,
            disabled,
            className,
            component,
            disableRipple,
            to,
            href,
            children,
            onClick,
            renderPopup,
            ...otherProps
        },
        ref
    ) => {
        const classes = useStyles({ loading })
        const [anchorEl, setAnchorEl] = useState(null)

        const handleClick = event => {
            if (renderPopup) {
                setAnchorEl(event.currentTarget)
            }

            if (onClick) {
                onClick(event)
            }
        }

        const closePopup = () => {
            setAnchorEl(null)
        }

        const isDisabled = disabled || loading

        const finalProps = { ...otherProps }

        let buttonComponent = component || 'button'
        if (to || href) {
            buttonComponent = Link
            finalProps.to = to
            finalProps.href = href
            finalProps.underline = 'none'
        }

        return (
            <>
                <Button
                    {...finalProps}
                    ref={ref}
                    className={classNames(className, classes.button, variant === 'link' && classes.link)}
                    disabled={isDisabled}
                    component={buttonComponent}
                    variant={(variant !== 'link' && variant) || null}
                    color={color}
                    onClick={(renderPopup && handleClick) || onClick}
                    disableRipple={
                        typeof finalProps.disableRipple === 'boolean'
                            ? finalProps.disableRipple
                            : variant === 'link'
                    }
                >
                    {children}
                    {loading && <LinearProgress className={classes.progress} color={color || 'primary'} />}
                </Button>
                {renderPopup &&
                    renderPopup({
                        anchorEl,
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        open: Boolean(anchorEl),
                        onClose: closePopup,
                    })}
            </>
        )
    }
)
