import React from 'react'
import { observer } from 'mobx-react-lite'
import { Switch, Route } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

import zone from '../../../utils/zone'

import ChooseOrganisation from './ChooseOrganisation'
import CreateOrganisation from './CreateOrganisation'

const useStyles = makeStyles(theme => ({
    container: {
        minHeight: '100%',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        background: theme.palette.background.default,
        overflow: 'auto',
    },
    paper: {
        marginTop: theme.spacing(10),
        paddingBottom: theme.spacing(3),
        maxWidth: 430,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            boxShadow: 'none',
            borderRadius: 0,
            marginTop: 0,
            height: '100%',
        },
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(3),
    },
}))

export default observer(() => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Paper className={classes.paper} elevation={3}>
                <div className={classes.logo}>
                    <img src={zone.icon} height={40} alt={zone.name} />
                </div>
                <Switch>
                    <Route path="/create" component={CreateOrganisation} />
                    <Route path="/" component={ChooseOrganisation} />
                </Switch>
            </Paper>
        </div>
    )
})
