import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

export const useInputLabelStyles = makeStyles(theme => ({
    root: ({ contained }) => ({
        fontSize: contained ? 14 : 15,
        fontWeight: 500,
        color: theme.palette.text.primary,
        transform: contained ? 'translate(10px, 10px) !important' : 'none !important',
        position: contained ? 'absolute' : 'static',
        marginBottom: contained ? 0 : 7,
        pointerEvents: contained ? 'none' : 'all',
    }),
    asterisk: {
        color: theme.palette.secondary.main,
    },
}))

const useStyles = makeStyles(theme => ({
    marker: {
        position: 'absolute',
        display: 'inline-block',
        borderRadius: 9,
        marginLeft: 5,
        width: 18,
        height: 18,
        lineHeight: '18px',
        verticalAlign: 'middle',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 600,
    },
    errorMarker: {
        background: theme.palette.error.main,
        color: 'white',
    },
    infoMarker: {
        background: 'rgba(0,0,0,0.12)',
    },
}))

export default React.forwardRef(({ className, infoText, errorText, children, ...inputLabelProps }, ref) => {
    const classes = useStyles()

    let marker
    if (typeof errorText === 'string' && errorText) {
        marker = (
            <Tooltip title={errorText}>
                <span className={classNames(classes.marker, classes.errorMarker)}>!</span>
            </Tooltip>
        )
    } else if (infoText) {
        marker = (
            <Tooltip title={infoText}>
                <span className={classNames(classes.marker, classes.infoMarker)}>?</span>
            </Tooltip>
        )
    }

    return (
        <InputLabel ref={ref} className={className} {...inputLabelProps}>
            {children}
            {marker}
        </InputLabel>
    )
})
