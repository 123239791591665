import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined'
import { makeStyles } from '@material-ui/core/styles'

import i18n from '../../../../i18n'

import zone from '../../../../utils/zone'

const useStyles = makeStyles(theme => ({
    iconButton: {
        '& .MuiIconButton-label': {
            width: 32,
            height: 32,
        },
        '& .Canny_BadgeContainer .Canny_Badge': {
            position: 'absolute',
            top: 2,
            right: 0,
            borderRadius: 4,
            backgroundColor: theme.palette.info.main,
            padding: 3,
            border: 'none',
            '&::before': {
                content: '"neu"',
                display: 'block',
                fontSize: 11,
                textTransform: 'uppercase',
                fontWeight: '600',
                color: '#fff',
            },
        },
    },
}))

export default observer(() => {
    const classes = useStyles()

    if (!zone.canny) {
        return null
    }

    useEffect(() => {
        window.Canny('initChangelog', {
            appID: zone.canny.appId,
            position: 'bottom',
            align: 'left',
        })
    }, [])

    return (
        <Tooltip title={i18n().navigation.updates}>
            <IconButton className={classes.iconButton} size="small" data-canny-changelog>
                <NewReleasesOutlinedIcon />
            </IconButton>
        </Tooltip>
    )
})
