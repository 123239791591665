import React from 'react'
import { observer } from 'mobx-react-lite'
import Avatar from '@material-ui/core/Avatar'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

import zone from '../../utils/zone'

const useStyles = makeStyles(theme => ({
    page: {
        background: theme.palette.background.default,
        paddingTop: theme.spacing(8),
        minHeight: '100%',
        [theme.breakpoints.down('sm')]: {
            background: 'white',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paper: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(2, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            boxShadow: 'none',
            padding: 0,
        },
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.grey.A400,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

export default observer(({ title, children, IconComponent }) => {
    const classes = useStyles()

    return (
        <div className={classes.page}>
            <Container component="main" maxWidth="xs">
                <div className={classes.header}>
                    <img src={zone.icon} alt={zone.name} height={40} />
                </div>
                <Paper className={classes.paper} elevation={4}>
                    <Avatar className={classes.avatar}>
                        <IconComponent />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {title}
                    </Typography>
                    {children}
                </Paper>
                <Box mt={8}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        <span>Copyright ©</span>
                        <span> </span>
                        <Link color="inherit" href={zone.website}>
                            {zone.vendor}
                        </Link>
                        <span> </span>
                        {new Date().getFullYear()}
                        {'.'}
                    </Typography>
                </Box>
            </Container>
        </div>
    )
})
