import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined'
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined'
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined'
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined'
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'

import i18n from '../../../i18n'

import zone from '../../../utils/zone'

export default source => ({
    get mainItems() {
        const { appOrganisation } = source.appState

        const result = [
            {
                label: i18n().navigation.start,
                Icon: AppsOutlinedIcon,
                route: '/',
            },
        ]

        if (appOrganisation.hasScope('contact')) {
            result.push({
                label: i18n().navigation.contacts,
                Icon: RecentActorsOutlinedIcon,
                route: '/contacts',
                children: appOrganisation.contactSegments.map(contactSegment => ({
                    label: contactSegment.name,
                    route: `/contacts/${contactSegment.key}`,
                })),
            })
        }

        if (appOrganisation.hasScope('finance')) {
            result.push({
                label: i18n().navigation.finances,
                Icon: AccountBalanceOutlinedIcon,
                route: '/finances',
            })
        }

        if (appOrganisation.hasScope('finance')) {
            result.push({
                label: i18n().navigation.accounting,
                Icon: CreditCardOutlinedIcon,
                route: '/accounting',
            })
        }

        if (appOrganisation.hasScope('organisation.edit')) {
            result.push({
                label: i18n().navigation.settings,
                Icon: SettingsApplicationsOutlinedIcon,
                route: '/settings',
            })
        }

        return result
    },

    get userItems() {
        const result = []

        if (zone.canny) {
            result.push({
                label: i18n().navigation.feedback,
                Icon: FeedbackOutlinedIcon,
                href: zone.canny.link,
                target: '_blank',
            })
        }

        if (zone.help) {
            result.push({
                label: i18n().navigation.help,
                Icon: HelpOutlineOutlinedIcon,
                href: zone.help,
                target: '_blank',
            })
        }

        result.push({
            label: i18n().navigation.userSettings,
            Icon: AccountCircleOutlinedIcon,
            route: '/user',
        })

        result.push({
            label: i18n().navigation.userSignOut,
            Icon: ExitToAppOutlinedIcon,
            onClick: () => source.appState.logout(),
        })

        return result
    },

    get mobileUserItems() {
        const result = []

        if (zone.intercom) {
            result.push({
                label: i18n().navigation.support,
                Icon: LiveHelpOutlinedIcon,
                onClick: () => window.Intercom('show'),
            })
        }

        if (zone.canny) {
            result.push({
                label: i18n().navigation.updates,
                Icon: NewReleasesOutlinedIcon,
                href: `${zone.canny.link}/changelog`,
                target: '_blank',
            })
        }

        return result.concat(this.userItems)
    },
})
