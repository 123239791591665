// eslint-disable-next-line max-classes-per-file
import Model from './Model'

class UserDataAvatar extends Model.Resource {
    constructor() {
        super({
            key: 'avatar',
            image: {
                maxWidth: 256,
                maxHeight: 256,
            },
        })
    }
}

class UserData extends Model {
    static CollectionName = 'userData'

    static Permissions = {
        read: true,
        write: true,
        delete: true,
        insert: 'everyone',
        fields: [
            'userId',
            'locale',
            'avatar',
            'name',
            'email',
            'organisation1',
            'organisation2',
            'organisation3',
            'organisation4',
            'organisation5',
            'organisation6',
            'organisation7',
            'organisation8',
            'organisation9',
            'organisation10',
        ],
    }

    static Fields = {
        userId: {
            type: String,
            required: true,
        },
        locale: {
            type: String,
            required: true,
        },
        avatar: {
            type: UserDataAvatar,
        },
        name: {
            type: String,
        },
        email: {
            type: String,
            format: 'email',
            required: true,
        },
        emailVerified: {
            type: Boolean,
            default: false,
        },

        organisation1: {
            type: Object,
        },
        organisation2: {
            type: Object,
        },
        organisation3: {
            type: Object,
        },
        organisation4: {
            type: Object,
        },
        organisation5: {
            type: Object,
        },
        organisation6: {
            type: Object,
        },
        organisation7: {
            type: Object,
        },
        organisation8: {
            type: Object,
        },
        organisation9: {
            type: Object,
        },
        organisation10: {
            type: Object,
        },
    }

    constructor(data) {
        super(UserData.Fields, data)
    }

    get resourcePath() {
        return `user/${this.userId}`
    }
}

export default Model.declare(UserData)
