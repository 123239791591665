/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'

export default React.forwardRef(({ to, children, ...properties }, ref) => {
    if (to) {
        return (
            <Link component={RouterLink} ref={ref} to={to} {...properties}>
                {children}
            </Link>
        )
    }

    return (
        <Link ref={ref} {...properties}>
            {children}
        </Link>
    )
})
