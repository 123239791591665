export default function stringSearchTerm(search, mode = 'contains', caseSensitive = false) {
    if (!search) {
        return search
    }

    // escape search term to not allow regexp
    const searchText = search.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')
    let regExpTerm = null

    switch (mode) {
        case 'contains':
            regExpTerm = `^(.*?)${searchText}(.*?)$`
            break
        case 'startsWith':
            regExpTerm = `^${searchText}(.*?)$`
            break
        case 'endsWith':
            regExpTerm = `^(.*?)${searchText}$`
            break
        case 'like':
            regExpTerm = `^${searchText.split('\\*').join('(.*?)')}$`
            break
        default:
            throw new Error('Unknown search mode')
    }

    return new RegExp(regExpTerm, caseSensitive ? '' : 'i')
}
