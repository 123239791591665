/* eslint-disable max-classes-per-file */
import moment from 'moment'

import Model from './Model'
import Organisation from './Organisation'

import i18n from '../i18n'

import Stitch from '../utils/Stitch'

import getNormalizedDayOfYear from '../utils/date/getNormalizedDayOfYear'

class ContactAvatar extends Model.Resource {
    constructor() {
        super({
            image: {
                maxWidth: 512,
                maxHeight: 512,
                preview: true,
            },
        })
    }
}

export class ContactPersonal extends Model {
    static Fields = {
        avatar: {
            type: ContactAvatar,
        },
        type: {
            type: String,
            enum: [
                'malePerson',
                'femalePerson',
                'otherPerson',
                'business',
                'club',
                'association',
                'otherOrganisation',
            ],
        },
        isPerson: {
            get: true,
            type: Boolean,
        },
        isOrganisation: {
            get: true,
            type: Boolean,
        },
        // -- used for sorting and filtering only
        name: {
            get: true,
            type: String,
        },
        personSalutation: {
            type: String,
        },
        personTitle: {
            type: String,
        },
        personFirstName: {
            type: String,
        },
        personLastName: {
            type: String,
        },
        organisationName: {
            type: String,
        },
        personBirthday: {
            type: Date,
        },
        personBirthdayOfYear: {
            get: true,
            type: Number,
        },
        personAge: {
            get: true,
            type: Number,
        },
    }

    constructor(data) {
        super(ContactPersonal.Fields, data)
    }

    get isPerson() {
        return this.type && ['malePerson', 'femalePerson', 'otherPerson'].includes(this.type)
    }

    get isOrganisation() {
        return this.type && !['malePerson', 'femalePerson', 'otherPerson'].includes(this.type)
    }

    get name() {
        const result = []

        if (!this.isPerson) {
            if (this.organisationName) {
                result.push(this.organisationName)
            }
        }

        if (this.personLastName) {
            result.push(this.personLastName)
        }

        if (this.personFirstName) {
            result.push(this.personFirstName)
        }

        return result.join(' ')
    }

    set name(value) {
        console.log('TODO: SET NAME')
    }

    get humanName() {
        return this.getHumanName(true)
    }

    getHumanName(asString = true) {
        const prefix = []
        const name = []

        if (this.isOrganisation) {
            if (this.organisationName) {
                prefix.push(i18n().contact.personal.typeValue[this.type])
                name.push(this.organisationName)
            }
        } else if (this.personFirstName || this.personLastName) {
            if (this.type && this.type !== 'otherPerson') {
                prefix.push(i18n().contact.personal.typeValue[this.type])
            }

            if (this.personTitle) {
                prefix.push(this.personTitle)
            }

            if (this.personFirstName) {
                name.push(this.personFirstName)
            }

            if (this.personLastName) {
                name.push(this.personLastName)
            }
        } else if (this.organisationName) {
            name.push(this.organisationName)
        }

        if (asString) {
            const str = prefix.concat(name)
            return str.join(' ')
        }

        return { prefix: prefix.join(' '), name: name.join(' ') }
    }

    get personBirthdayOfYear() {
        if (this.personBirthday) {
            return getNormalizedDayOfYear(this.personBirthday)
        }
        return null
    }

    get personAge() {
        if (this.personBirthday) {
            return moment().diff(this.personBirthday, 'years')
        }
        return null
    }
}

export class ContactAddress extends Model {
    static Fields = {
        suffix: {
            type: String,
        },
        street: {
            type: String,
        },
        zip: {
            type: String,
        },
        city: {
            type: String,
        },
        country: {
            type: String,
        },
    }

    constructor(data) {
        super(ContactAddress.Fields, data)
    }

    get address() {
        if (this.street && this.zip && this.city) {
            const result = `${this.street}, ${this.zip} ${this.city}`
            return result
        }
        return ''
    }

    set address(value) {
        this.street = null
        this.zip = null
        this.city = null

        if (value) {
            const regEx = /^(.*?)(?:,[ ]*)?([0-9]{5})(?:[ ]*)(.*?)$/
            const match = regEx.exec(value)
            if (match) {
                this.street = match[1].trim()
                this.zip = match[2].trim()
                this.city = match[3].trim()
            }
        }
    }
}

export class ContactCommunication extends Model {
    static Fields = {
        email: {
            type: String,
            format: 'email',
        },
        mobilePhone: {
            type: String,
            format: 'mobilePhone',
        },
        mayContact: {
            type: Boolean,
            default: true,
        },
        chapsterSync: {
            type: Boolean,
            default: true,
        },
    }

    constructor(data) {
        super(ContactCommunication.Fields, data)
    }

    get status() {
        if (!this.hasContact) {
            return 'noContact'
        }
        if (!this.mayContact) {
            return 'notAllowed'
        }
        return null
    }

    get hasContact() {
        return this.email || this.phone
    }
}

export class ContactMembership extends Model {
    static Fields = {
        number: {
            type: Number,
        },
        enterDate: {
            type: Date,
        },
        leaveDate: {
            type: Date,
        },
        sections: {
            type: [String],
        },
        status: {
            get: true,
            type: String,
            enum: ['hasLeft', 'willLeave', 'isActive', 'willEnter'],
            requred: true,
        },
    }

    constructor(data) {
        super(ContactMembership.Fields, data)
    }

    get status() {
        if (this.leaveDate) {
            if (moment(this.leaveDate).isSameOrBefore(moment())) {
                return 'hasLeft'
            }
            return 'willLeave'
        }

        if (this.enterDate) {
            if (moment(this.enterDate).isSameOrBefore(moment())) {
                return 'isActive'
            }
            return 'willEnter'
        }

        return null
    }
}

export class ContactNote extends Model {
    static Fields = {
        createdAt: {
            type: Date,
            default: () => new Date(),
        },
        createdBy: {
            type: String,
            default: () => Stitch.userId,
        },
        followUpAt: {
            type: Date,
        },
        content: {
            type: String,
        },
    }
}

class Contact extends Model {
    static CollectionName = 'contacts'

    static Fields = {
        organisation: {
            type: Organisation,
            required: true,
        },
        type: {
            type: String,
            enum: ['member', 'contact'],
            required: true,
        },
        personal: {
            type: ContactPersonal,
        },
        tags: {
            type: [String],
        },
        previousTags: {
            type: [String],
        },
        balance: {
            type: Number,
            default: () => 0,
        },
        primaryDeviatingContact: {
            type: Stitch.BSON.ObjectId,
        },
        address: {
            type: ContactAddress,
        },
        communication: {
            type: ContactCommunication,
        },
        notes: {
            type: [ContactNote],
        },
        // type = member only
        membership: {
            type: ContactMembership,
        },
    }

    constructor(data) {
        super(Contact.Fields, data)
    }
}

export default Model.declare(Contact)
