import React, { useMemo } from 'react'
import uuidv4 from 'uuid/v4'
import { fade, makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

import InputLabel, { useInputLabelStyles } from './InputLabel'

export const useControlStyles = makeStyles(() => ({
    root: ({ fullHeight }) => ({
        height: fullHeight && '100%',
    }),
}))

export const useInputStyles = makeStyles(theme => ({
    root: ({ fullHeight, inlined, contained }) => ({
        height: fullHeight && '100%',
        border: inlined ? '0px solid transparent' : '1px solid #e2e2e1',
        borderRadius: inlined ? 0 : 4,
        overflow: 'hidden',
        backgroundColor: '#fff !important',
        padding: '0 !important',
        font: 'inherit',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '& .MuiInputAdornment-filled': {
            marginLeft: '7px !important',
            marginRight: '-5px !important',
            marginTop: contained ? '20px !important' : '2px !important',
        },
    }),
    error: {
        borderColor: theme.palette.error.main,
        boxShadow: `${fade(theme.palette.error.light, 1)} 0 0 0 1px`,
    },
    focused: ({ inlined }) => ({
        boxShadow: inlined ? 'none' : `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: inlined ? 'transparent' : `${theme.palette.primary.main} !important`,
    }),
    input: ({ contained, inlined }) => ({
        padding: contained ? '30px 10px 10px 10px' : inlined ? '0px' : '11px 10px 9px 10px',
    }),
}))

export default React.forwardRef(
    (
        {
            InputProps,
            InputLabelProps,
            inputProps,
            fullHeight,
            variant,
            type,
            size,
            info,
            error,
            onKeyDown,
            onChange,
            onSubmit,
            ...properties
        },
        ref
    ) => {
        if (type === 'search') {
            throw new Error('For type=search use dedicated TextSearchInput instead.')
        }

        const contained = variant === 'contained'
        const inlined = variant === 'inlined'
        const controlClasses = useControlStyles({ contained, inlined, fullHeight })
        const inputClasses = useInputStyles({ contained, inlined, fullHeight })
        const inputLabelClasses = useInputLabelStyles({ contained })

        const handleChange = useMemo(
            () => evt => {
                if (onChange) {
                    onChange(evt.target.value)
                }
            },
            [onChange]
        )

        const handleKeyDown = useMemo(
            () => evt => {
                if (evt.keyCode === 13 && onSubmit) {
                    onSubmit(evt)
                }

                if (onKeyDown) {
                    onKeyDown(evt)
                }
            },
            [onSubmit, onKeyDown]
        )

        const fieldId = React.useMemo(uuidv4, [])

        return (
            <TextField
                {...properties}
                error={!!error}
                ref={ref}
                type={type}
                variant="filled"
                classes={controlClasses}
                InputProps={{
                    ...InputProps,
                    classes: inputClasses,
                    disableUnderline: true,
                    id: fieldId,
                }}
                inputProps={{ size: size || null, ...inputProps }}
                InputLabelProps={{
                    ...InputLabelProps,
                    classes: inputLabelClasses,
                    shrink: true,
                    htmlFor: fieldId,
                    infoText: info,
                    errorText: error,
                    component: InputLabel,
                }}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
        )
    }
)
