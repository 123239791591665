let activeChangeStreams = []

export async function aquireWatcher(collection, query, onChange) {
    let watchQuery

    if (Array.isArray(query)) {
        // Watching a set of ids so just take as-is
        watchQuery = query
    } else {
        // We need to convert the query to work on deletions and for any other
        // operation to work on the $match of the actual fullDocument property
        // of the incoming changeEvent

        const getPropertyName = property => {
            if (property.charAt(0) === '$') {
                return property
            }

            return `fullDocument.${property}`
        }

        const convertQueryValue = queryValue => {
            if (Array.isArray(queryValue)) {
                return queryValue.map(item => convertQueryValue(item))
            }

            if (typeof queryValue === 'object' && Object.keys(queryValue).length > 0) {
                const result = {}

                for (const property in queryValue) {
                    result[getPropertyName(property)] = convertQueryValue(queryValue[property])
                }

                return result
            }

            return queryValue
        }

        watchQuery = {
            $or: [
                {
                    operationType: 'delete',
                },
                convertQueryValue(query),
            ],
        }
    }

    const changeStream = await collection.watch(watchQuery)
    changeStream.onNext(event => {
        const { operationType, fullDocument, documentKey } = event
        onChange(operationType, fullDocument, documentKey && documentKey._id)
    })

    if (process.env.NODE_ENV !== 'production') {
        activeChangeStreams.push(changeStream)
    }

    return changeStream
}

export function releaseWatcher(changeStream) {
    if (process.env.NODE_ENV !== 'production') {
        activeChangeStreams = activeChangeStreams.filter(item => item !== changeStream)
    }

    changeStream.close()
}

if (process.env.NODE_ENV === 'test') {
    window.afterAll(() => {
        if (activeChangeStreams.length) {
            console.error(`There are ${activeChangeStreams.length} open watchers left.`)
        }
    })
} else if (process.env.NODE_ENV === 'development') {
    window.addEventListener('beforeunload', event => {
        if (activeChangeStreams.length) {
            window.alert(`There are ${activeChangeStreams.length} open watchers left.`)
            event.preventDefault()
            event.returnValue = ''
        }
    })
}
