import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined'
import { makeStyles } from '@material-ui/core/styles'

import Link from '../../components/Link'
import Button from '../../components/Button'

import Stitch from '../../utils/Stitch'

import i18n from '../../i18n'

import AuthPage from './AuthPage'

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    alert: {
        margin: theme.spacing(3, 0, 1, 0),
        width: '100%',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

export default observer(() => {
    const classes = useStyles()

    const [email, setEMail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)

    const handleSignup = async evt => {
        evt.preventDefault()

        setErrorMessage('')
        setSubmitting(true)

        const error = await Stitch.signUpWithEMail(email, password, passwordRepeat)

        if (error) {
            setSubmitting(false)
            setErrorMessage(i18n().auth.signUp.errors[error] || i18n().misc.errors.unknown)
        } else {
            window.location.reload(true /* force */)
        }
    }

    return (
        <AuthPage title={i18n().auth.signUp.title} IconComponent={PersonAddOutlinedIcon}>
            {errorMessage && (
                <Alert severity="error" variant="filled" className={classes.alert}>
                    {errorMessage}
                </Alert>
            )}
            <form className={classes.form} onSubmit={handleSignup}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    label={i18n().auth.signUp.email}
                    autoComplete="email"
                    type="email"
                    value={email}
                    onChange={evt => setEMail(evt.target.value)}
                    autoFocus
                    required
                    fullWidth
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    label={i18n().auth.signUp.password}
                    autoComplete="current-password"
                    type="password"
                    value={password}
                    onChange={evt => setPassword(evt.target.value)}
                    required
                    fullWidth
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    label={i18n().auth.signUp.passwordRepeat}
                    autoComplete="current-password"
                    type="password"
                    value={passwordRepeat}
                    onChange={evt => setPasswordRepeat(evt.target.value)}
                    required
                    fullWidth
                />
                <Button
                    disabled={!email || !password || !passwordRepeat}
                    loading={isSubmitting}
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    {i18n().auth.signUp.action}
                </Button>
                <Grid container justify="center">
                    <Link to="/login" variant="body2">
                        {i18n().auth.signUp.alreadyRegisteredLogin}
                    </Link>
                </Grid>
            </form>
        </AuthPage>
    )
})
