export default function getBrowserLanguage() {
    const { navigator } = window
    let result

    if (navigator.languages && navigator.languages.length) {
        result = navigator.languages[0]
    } else {
        result = navigator.userLanguage || navigator.language || navigator.browserLanguage
    }

    if (!result) {
        return null
    }

    return result.split('-')[0]
}
