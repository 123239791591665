import React from 'react'
import { observer } from 'mobx-react-lite'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'

const useFormLabelStyles = makeStyles(theme => ({
    root: {
        fontSize: 15,
        fontWeight: 500,
        marginBottom: 7,
        color: `${theme.palette.text.primary} !important`,
    },
    asterisk: {
        color: theme.palette.secondary.main,
    },
}))

const useFormControlLabelStyles = makeStyles(() => ({
    root: {
        cursor: 'default !important',
    },
}))

export default observer(({ label, value, options, multiple, helperText, onChange, ...properties }) => {
    const formLabelClasses = useFormLabelStyles()
    const formControlLabelClasses = useFormControlLabelStyles()

    const handleChange = evt => {
        if (onChange) {
            let newValue

            if (multiple) {
                newValue = (value || []).filter(x => x !== evt.target.value)
                if (evt.target.checked) {
                    newValue.push(evt.target.value)
                }
            } else {
                newValue = evt.target.value
            }

            onChange(newValue, evt.target.value, evt.target.checked)
        }
    }

    let selectGroup
    if (multiple) {
        selectGroup = (
            <FormGroup>
                {options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        classes={formControlLabelClasses}
                        control={
                            <Checkbox
                                checked={!!(value && value.indexOf(option.value) >= 0)}
                                onChange={handleChange}
                                value={option.value}
                            />
                        }
                        label={option.label}
                        disabled={option.disabled}
                    />
                ))}
            </FormGroup>
        )
    } else {
        selectGroup = (
            <RadioGroup value={value} onChange={handleChange}>
                {options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        classes={formControlLabelClasses}
                        value={option.value}
                        control={<Radio />}
                        label={option.label}
                        disabled={option.disabled}
                    />
                ))}
            </RadioGroup>
        )
    }

    return (
        <FormControl {...properties} component="fieldset">
            {label && (
                <FormLabel classes={formLabelClasses} component="legend">
                    {label}
                </FormLabel>
            )}
            {selectGroup}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
})
