import React, { useContext, useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListSubheader from '@material-ui/core/ListSubheader'
import CircularProgress from '@material-ui/core/CircularProgress'
import BusinessIcon from '@material-ui/icons/Business'
import { makeStyles } from '@material-ui/core/styles'

import Avatar from '../../../components/Avatar'
import Link from '../../../components/Link'

import i18n from '../../../i18n'

import OrganisationUser from '../../../models/OrganisationUser'

import AppState from '../AppState'

const useStyles = makeStyles(theme => ({
    loaderContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(3),
    },
    listHeaderItem: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    listItem: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}))

export default observer(() => {
    const appState = useContext(AppState)
    const classes = useStyles()
    const history = useHistory()

    const [loading, setLoading] = useState(true)
    const [organisations, setOrganisations] = useState([])

    useEffect(() => {
        const loadOrganisations = async () => {
            const organisationUsers = OrganisationUser.createQuery({ userId: appState.userData.userId })
            if ((await organisationUsers.loadAll()) > 0) {
                const loadedOrganisations = await Promise.all(
                    organisationUsers.documents.map(async organisationUser => {
                        if (!(await organisationUser.populate('organisation'))) {
                            return null
                        }
                        return organisationUser.organisation
                    })
                )

                setOrganisations(loadedOrganisations.filter(x => !!x))
            }

            setLoading(false)
        }

        loadOrganisations()
    }, [appState.userData.userId])

    const handleCreateNew = () => {
        history.push(`/create`)
    }

    if (loading) {
        return (
            <div className={classes.loaderContainer}>
                <CircularProgress color="primary" size={20} style={{}} />
            </div>
        )
    }

    return (
        <List>
            <ListSubheader className={classes.listHeaderItem} disableSticky>
                {i18n().organisation.yours}
            </ListSubheader>
            {organisations.map(organisation => (
                <ListItem
                    key={organisation.id}
                    button
                    className={classes.listItem}
                    component={Link}
                    href={`/${organisation.id}`}
                    underline="none"
                >
                    <ListItemAvatar>
                        <Avatar src={organisation.avatar.url} name={organisation.name} />
                    </ListItemAvatar>
                    <ListItemText primary={organisation.name} />
                </ListItem>
            ))}
            <ListItem button className={classes.listItem} onClick={handleCreateNew}>
                <ListItemAvatar>
                    <Avatar>
                        <BusinessIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={i18n().organisation.create.label}
                    secondary={i18n().organisation.create.description}
                />
            </ListItem>
        </List>
    )
})
