import React from 'react'
import { observer } from 'mobx-react-lite'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

export default observer(({ type }) => {
    return (
        <Grid container justify="center" alignItems="center" direction="column" style={{ height: '100%' }}>
            <CircularProgress size={30} color={type === 'app' ? 'secondary' : 'primary'} />
        </Grid>
    )
})
