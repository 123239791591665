import Model from './Model'
import Organisation from './Organisation'

class ContactTag extends Model {
    static CollectionName = 'contactTags'

    static Fields = {
        organisation: {
            type: Organisation,
            required: true,
        },
        type: {
            type: String,
            enum: ['member', 'contact'],
            required: true,
        },
        tag: {
            type: String,
            required: true,
        },
        tagNormalized: {
            type: String,
            required: true,
        },
        color: {
            type: String,
        },
        usageCount: {
            type: Number,
            default: 0,
        },
    }

    constructor(data) {
        super(ContactTag.Fields, data)
    }
}

export const getAvailableContactTags = async (organisation, options = {}) => {
    const availableTagsQuery = ContactTag.createQuery(
        { organisation: organisation._id, usageCount: { $gt: 0 } },
        {},
        options
    )

    const availableTags = await availableTagsQuery.execute()

    return availableTags
}

export default Model.declare(ContactTag)
