import React from 'react'
import { observer } from 'mobx-react-lite'
import { useRouteMatch } from 'react-router-dom'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Link from '../../../components/Link'

const useStyles = makeStyles(theme => ({
    listItem: ({ isActive }) => ({
        color: isActive ? theme.palette.secondary.main : 'inherit',
        '&:hover': {
            color: theme.palette.secondary.main,
        },
    }),
    listItemLabel: ({ isActive, isRoot }) => ({
        marginLeft: theme.spacing(2),
        '&, & > span': {
            fontWeight: isActive ? '600' : 'normal',
            fontSize: isRoot ? 16 : 'inherit',
        },
    }),
    nestedList: {
        marginBottom: theme.spacing(1),
    },
}))

const NavigationItem = observer(({ navigationItem, level = 1, onActivate }) => {
    let isActive = false

    if (navigationItem.route) {
        const match = useRouteMatch(navigationItem.route)
        isActive = match && (navigationItem.route !== '/' || match.isExact)
    }

    const classes = useStyles({ isActive, isRoot: level === 1 })
    const theme = useTheme()

    const handleClick = () => {
        if (onActivate) {
            onActivate()
        }

        if (navigationItem.onClick) {
            navigationItem.onClick()
        }
    }

    let subItems = null

    if (navigationItem.children) {
        subItems = (
            <Collapse in={isActive} timeout="auto" unmountOnExit>
                <List component="div" className={classes.nestedList} disablePadding>
                    {navigationItem.children.map((subNavigationItem, index) => (
                        <NavigationItem
                            key={`sub-${index}`}
                            navigationItem={subNavigationItem}
                            level={level + 1}
                            onActivate={onActivate}
                        />
                    ))}
                </List>
            </Collapse>
        )
    }

    return (
        <>
            <ListItem
                dense={level > 1}
                className={classes.listItem}
                style={{ paddingLeft: level * theme.spacing(3) }}
                onClick={handleClick}
                component={Link}
                href={navigationItem.href}
                target={navigationItem.target}
                to={typeof navigationItem.route === 'string' ? `${navigationItem.route}` : null}
                underline="none"
            >
                {navigationItem.Icon && <navigationItem.Icon />}
                <ListItemText className={classes.listItemLabel} primary={navigationItem.label} />
            </ListItem>
            {subItems}
        </>
    )
})

export default NavigationItem
