export default (value, isRequired /* validationSettings */) => {
    if (isRequired && value !== true && value !== false) {
        return 'required'
    }

    if (value !== null && value !== undefined && value !== true && value !== false) {
        return 'invalid_type'
    }

    return null
}
