import { createContext } from 'react'
import { decorate, observable } from 'mobx'
import * as Sentry from '@sentry/browser'

import Stitch from '../../utils/Stitch'

import Model from '../../models/Model'
import UserData from '../../models/UserData'
import OrganisationUser from '../../models/OrganisationUser'

import i18n from '../../i18n'

import AppOrganisation from './AppOrganisation'

class AppState {
    initialLoading = true

    userData = null

    appOrganisation = null

    async init(organisationId) {
        const { authUser } = Stitch
        const userData = new UserData()

        // Try to load the current user's data. This may fail on initial user setup
        // and in that case, we'll create the initial user data first
        if (!(await userData.load({ userId: authUser.id }))) {
            userData.userId = authUser.id
            userData.locale = i18n.getLocale()
            userData.email = authUser.profile.email
            userData.name = authUser.profile.email
            await userData.save()
        }

        i18n.setLocale(userData.locale)

        // Assign userData data now, not before
        this.userData = userData

        // Some scopes for tracing if in production
        if (process.env.NODE_ENV === 'production') {
            Sentry.configureScope(scope => {
                scope.setUser({ id: userData.id })
            })
        }

        if (organisationId) {
            let organisationObjectId
            try {
                organisationObjectId = Model.id(organisationId)
            } catch (e) {
                // Invalid id so ignore to go to main panel
            }

            if (organisationObjectId) {
                // Try to load the request user organisation and if it fails behave as there was none at all
                const activeOrganisationUser = new OrganisationUser()

                if (
                    await activeOrganisationUser.load({
                        organisation: organisationObjectId,
                        userId: userData.userId,
                    })
                ) {
                    const appOrganisation = new AppOrganisation()

                    if (await appOrganisation.init(activeOrganisationUser)) {
                        this.appOrganisation = appOrganisation
                    }
                }
            }
        }

        await Stitch.client.auth.refreshCustomData()

        this.initialLoading = false
    }

    async release() {
        if (this.appOrganisation) {
            this.appOrganisation.release()
        }
    }

    async logout() {
        await Stitch.logout()

        // -- enforce reload
        window.location.href = '/'
    }
}

decorate(AppState, {
    initialLoading: observable,
    userData: observable,
    appOrganisation: observable,
})

export default createContext(new AppState())
