/* eslint-disable no-bitwise */
function stringToInteger(string) {
    let hash = 0

    for (let i = 0; i < string.length; i += 1) {
        const chr = string.charCodeAt(i)
        hash = (hash << 5) - hash + chr
        hash |= 0
    }

    return hash
}

function randomSeedNumber(initialSeed, min, max) {
    let seed = initialSeed

    if (typeof seed === 'string') {
        seed = stringToInteger(seed)
    }

    // Seeded random algorithm from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
    seed = (seed * 9301 + 49297) % 233280
    const rnd = seed / 233280.0
    return Math.floor(min + rnd * (max - min))
}

export default function getRandomColor(seed, light = 50) {
    return `hsl(${randomSeedNumber(seed, 0, 360)}, ${light}%, ${light}%)`
}
