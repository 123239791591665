import Model from './Model'
import Organisation from './Organisation'

class MemberSection extends Model {
    static CollectionName = 'memberSections'

    static Fields = {
        organisation: {
            type: Organisation,
            required: true,
        },
        section: {
            type: String,
            required: true,
        },
        sectionNormalized: {
            type: String,
            required: true,
        },
        color: {
            type: String,
        },
        usageCount: {
            type: Number,
            default: 0,
        },
    }

    constructor(data) {
        super(MemberSection.Fields, data)
    }
}

export const getAvailableMemberSections = async (organisation, options = {}) => {
    const availableSectionsQuery = MemberSection.createQuery(
        { organisation: organisation._id, usageCount: { $gt: 0 } },
        {},
        options
    )

    const availableSections = await availableSectionsQuery.execute()

    return availableSections
}

export default Model.declare(MemberSection)
