import React from 'react'
import { observer } from 'mobx-react-lite'
import { makeStyles } from '@material-ui/core/styles'

import UserNavigationUpdates from './UserNavigationUpdates'
import UserNavigationSupport from './UserNavigationSupport'
import UserNavigationMenu from './UserNavigationMenu'

const useStyles = makeStyles(theme => ({
    userNavigation: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        '& > button': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
}))

export default observer(({ navigationState }) => {
    const classes = useStyles()

    return (
        <div className={classes.userNavigation}>
            <UserNavigationUpdates />
            <UserNavigationSupport />
            <UserNavigationMenu navigationState={navigationState} />
        </div>
    )
})
