import React, { lazy } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import { Route } from 'react-router-dom'

import zone from './utils/zone'

import Stitch from './utils/Stitch'

import App from './modules/App/App'
import Auth from './modules/Auth/Auth'

if (process.env.NODE_ENV === 'production') {
    if (!process.env.REACT_APP_SENTRY_DSN) {
        throw new Error('Missing REACT_APP_SENTRY_DSN process env')
    }

    if (!process.env.REACT_APP_SENTRY_RELEASE) {
        throw new Error('Missing REACT_APP_SENTRY_RELEASE process env')
    }

    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: process.env.REACT_APP_SENTRY_RELEASE,
    })
}

// -- set title & favicons as early as possible
document.title = zone.name
document.getElementById('favicon').setAttribute('href', zone.favicon)

const Home = lazy(() => import('./modules/Home/Home'))
const Contacts = lazy(() => import('./modules/Contacts/Contacts'))
const Settings = lazy(() => import('./modules/Settings/Settings'))
const User = lazy(() => import('./modules/User/User'))

let appContent

if (!Stitch.hasSession) {
    appContent = <Auth />
} else {
    appContent = (
        <App>
            <Route exact path="/" component={Home} />
            <Route path="/contacts/:segment?" component={Contacts} />
            <Route path="/settings" component={Settings} />
            <Route path="/user" component={User} />
        </App>
    )
}

ReactDOM.render(appContent, document.querySelector('#root'))
