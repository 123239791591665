import React from 'react'
import { observer } from 'mobx-react-lite'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'

import zone from '../../../../utils/zone'

import NavigationItem from '../NavigationItem'

const useStyles = makeStyles(theme => ({
    listDivider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    drawerPaper: {
        maxWidth: 300,
        width: '100%',
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
    },
}))

export default observer(({ open, onToggleDrawer, navigationState }) => {
    const classes = useStyles()
    return (
        <Drawer
            variant="temporary"
            anchor="left"
            open={open}
            onClose={onToggleDrawer}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <div className={classes.logo}>
                <img src={zone.icon} width={50} alt={zone.name} />
            </div>
            <List component="div">
                {navigationState.mainItems.map((navigationItem, index) => (
                    <NavigationItem
                        key={`main-${index}`}
                        navigationItem={navigationItem}
                        onActivate={onToggleDrawer}
                    />
                ))}
                <Divider className={classes.listDivider} light />
                {navigationState.mobileUserItems.map((navigationItem, index) => (
                    <NavigationItem
                        key={`main-${index}`}
                        navigationItem={navigationItem}
                        onActivate={onToggleDrawer}
                    />
                ))}
            </List>
        </Drawer>
    )
})
