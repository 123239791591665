/* eslint-disable */
import moment from 'moment'
import 'moment/locale/de'
import parseDecimalNumber from 'parse-decimal-number'

import { enUS, deDE } from '@material-ui/core/locale'

import getLocaleFromBrowser from '../utils/browser/getLocaleFromBrowser'

import de from './de'
import en from './en'

import prettyBytes from './prettyBytes'

const languages = {
    de,
    en,
}

const muiLocales = {
    de: deDE,
    en: enUS,
}

let currentLocale = 'en'
let currentCountry = null

function enhanceLanguages(properties) {
    function createTemplateFunction(templateString) {
        const templateFunction = new Function(`return \`${templateString}\`;`)

        return function(templateVars) {
            return templateFunction.call(templateVars)
        }
    }

    function createMultiTemplateFunction({ single, multi, isMulti }) {
        const singleFunction = createTemplateFunction(single)
        const multiFunction = createTemplateFunction(multi)

        return function(templateVars, multi) {
            const needMulti = multi || (isMulti && isMulti(templateVars))
            return needMulti ? multiFunction(templateVars) : singleFunction(templateVars)
        }
    }

    for (const prop in properties) {
        const value = properties[prop]
        if (typeof value === 'string') {
            if (/\$\{.+\}/.test(value)) {
                properties[prop] = createTemplateFunction(value)
            }
        } else if (value.single && value.multi) {
            properties[prop] = createMultiTemplateFunction(value)
        } else {
            enhanceLanguages(value)
        }
    }
}

enhanceLanguages(languages)

function i18n(locale) {
    const actualLocale = locale || currentLocale
    return languages[actualLocale] || {}
}

i18n.getLocale = function() {
    return currentLocale
}

i18n.setLocale = function(locale) {
    currentLocale = locale
    moment.locale(currentLocale)
}

i18n.getCountry = function() {
    return currentCountry
}

i18n.setCountry = function(country) {
    currentCountry = country
}

//
// Enhance each language with some utilities
//
for (const locale in languages) {
    const language = languages[locale]

    if (!muiLocales[locale]) {
        console.warn(`Missing MUI locale for ${locale}`)
    }

    language.mui = muiLocales[locale] || muiLocales['en']

    language.number = {
        parseFloat: parseDecimalNumber.withOptions(language.misc.number.delimiters),
        formatFloat: (number, maxDecimals = 2) =>
            number
                .toFixed(number % 1 === 0 ? 0 : maxDecimals)
                .replace('.', language.misc.number.delimiters.decimal),
        formatNumber: (number, type, options) =>
            number.toLocaleString(currentCountry ? `${locale}-${currentCountry.code}` : locale, {
                maximumFractionDigits: 2,
                style: type || 'decimal',
                currency: currentCountry ? currentCountry.currency : undefined,
                ...options,
            }),
        prettyBytes: bytes => prettyBytes(bytes, locale),
    }
}

// Set default locale from browser if any
const browserLocale = getLocaleFromBrowser()
if (browserLocale && languages[browserLocale]) {
    i18n.setLocale(browserLocale)
}

export default i18n
