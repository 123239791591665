import Stitch from '../../../utils/Stitch'

import validateBoolean from './validateBoolean'
import validateDate from './validateDate'
import validateString from './validateString'
import validateNumber from './validateNumber'
import validateObject from './validateObject'
import validateObjectId from './validateObjectId'

export default function validate(type, value, isRequired, validationSettings) {
    if (type === String) {
        return validateString(value, isRequired, validationSettings)
    }
    if (type === Number) {
        return validateNumber(value, isRequired, validationSettings)
    }
    if (type === Boolean) {
        return validateBoolean(value, isRequired, validationSettings)
    }
    if (type === Date) {
        return validateDate(value, isRequired, validationSettings)
    }
    if (type === Object) {
        return validateObject(value, isRequired, validationSettings)
    }
    if (type === Stitch.BSON.ObjectId) {
        return validateObjectId(value, isRequired)
    }

    throw new Error(`Unknown validator type provided "${type}"`)
}
