import {
    Stitch,
    RemoteMongoClient,
    AnonymousCredential,
    UserPasswordCredential,
    UserPasswordAuthProviderClient,
    BSON,
} from 'mongodb-stitch-browser-sdk'

import { AwsServiceClient, AwsRequest } from 'mongodb-stitch-browser-services-aws'

import Config from './Config'

import zone from './zone'

class StitchWrapper {
    client

    db

    aws

    sentMails = {}

    constructor() {
        this.client = Stitch.initializeDefaultAppClient(Config.appId)
        this.db = this.client.getServiceClient(RemoteMongoClient.factory, 'database').db(Config.dbName)
        this.aws = this.client.getServiceClient(AwsServiceClient.factory, 'aws')
    }

    get hasSession() {
        return this.client.auth.isLoggedIn && !!this.client.auth.user
    }

    // Ensures the user's custom data id for each org is a valid ObjectId Instance
    get userData() {
        const mappedOrganisationFields = {}

        Object.keys(this.authUser.customData)
            .filter(field => field.startsWith('organisation'))
            .forEach(field => {
                const organisationUserData = this.authUser.customData[field] || {}

                mappedOrganisationFields[field] = {
                    ...organisationUserData,
                    id: organisationUserData.id
                        ? new BSON.ObjectID(organisationUserData.id.$oid)
                        : organisationUserData.id,
                }
            })

        return {
            ...this.authUser.customData,
            ...mappedOrganisationFields,
        }
    }

    get authUser() {
        return this.hasSession ? this.client.auth.user : null
    }

    get userId() {
        return this.hasSession ? this.client.auth.user.id : null
    }

    call(functionName, ...args) {
        return this.client.callFunction(functionName, args)
    }

    getWebhookUrl(webhookName, query) {
        const queryStrings = []
        for (const key in query) {
            if (query[key] !== null && query[key] !== undefined) {
                queryStrings.push(`${key}=${encodeURIComponent(query[key])}`)
            }
        }

        const queryString = queryStrings.length ? `?${queryStrings.join('&')}` : ''

        return `https://eu-west-1.aws.webhooks.mongodb-stitch.com/api/client/v2.0/app/${Config.appId}/service/webhooks/incoming_webhook/${webhookName}${queryString}`
    }

    getStorageUrl(path, action = 'get') {
        return this.getWebhookUrl('storageUrl', { action, path })
    }

    async loginWithGuest() {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('May not be called outside a test environment.')
        }

        await this.logout()

        await this.client.auth.loginWithCredential(new AnonymousCredential())
    }

    async loginWithTestUser(testUserName) {
        if (process.env.NODE_ENV !== 'test') {
            throw new Error('May not be called outside a test environment.')
        }

        await this.logout()

        const loginResult = await this.loginWithEMail(`cotest-${testUserName}@campai.com`, 'test1234')
        if (loginResult !== null) {
            const signupResult = await this.signUpWithEMail(
                `cotest-${testUserName}@campai.com`,
                'test1234',
                'test1234'
            )
            if (signupResult != null) {
                throw new Error('Unable to sign test user up')
            }
        }

        return this.userId
    }

    async loginWithEMail(email, password) {
        if (this.hasSession) {
            throw new Error('Already logged in.')
        }

        try {
            const credential = new UserPasswordCredential(email, password)
            await this.client.auth.loginWithCredential(credential)
            return null
        } catch (e) {
            switch (e.message) {
                case 'invalid username':
                case 'invalid password':
                case 'invalid username/password':
                    return 'invalidCredentials'
                default:
                    console.error('Unknown loginWithEMail error', e)
                    return 'unknownError'
            }
        }
    }

    async signUpWithEMail(email, password, passwordRepeat) {
        if (this.hasSession) {
            throw new Error('Already logged in.')
        }

        const emailPasswordClient = this.client.auth.getProviderClient(UserPasswordAuthProviderClient.factory)

        if (password && passwordRepeat && password !== passwordRepeat) {
            return 'passwordMissmatch'
        }

        try {
            await emailPasswordClient.registerWithEmail(email, password)
            return this.loginWithEMail(email, password)
        } catch (e) {
            switch (e.message) {
                case 'email invalid':
                    return 'invalidEmail'
                case 'password must be between 6 and 128 characters':
                    return 'invalidPassword'
                case 'name already in use':
                    return 'alreadySignedUp'
                default:
                    console.error('Unknown signUpWithEMail error', e)
                    return 'unknownError'
            }
        }
    }

    async sendResetPasswordEmail(email) {
        const emailPasswordClient = Stitch.defaultAppClient.auth.getProviderClient(
            UserPasswordAuthProviderClient.factory
        )

        try {
            await emailPasswordClient.sendResetPasswordEmail(email)
            return null
        } catch (e) {
            switch (e.message) {
                case 'user not found':
                    return 'invalidEmail'
                default:
                    console.error('Unknown signUpWithEMail error', e)
                    return 'unknownError'
            }
        }
    }

    async logout() {
        await this.client.auth.logout()
        return true
    }

    async sendEMail(receivers, subject, text) {
        const ToAddresses = Array.isArray(receivers) ? receivers : [receivers]

        const args = {
            Destination: {
                ToAddresses,
            },
            Message: {
                Body: {
                    Text: {
                        Charset: 'UTF-8',
                        Data: `${text}\n\n--\n${zone.website}\n${zone.vendorImprint}`,
                    },
                },
                Subject: {
                    Charset: 'UTF-8',
                    Data: subject,
                },
            },
            Source: zone.mailerFromAddress,
        }

        if (process.env.NODE_ENV === 'test') {
            ToAddresses.forEach(toAddresse => {
                if (!this.sentMails[toAddresse]) this.sentMails[toAddresse] = []

                this.sentMails[toAddresse].push(args)
            })

            return true
        }

        const request = new AwsRequest.Builder()
            .withService('ses')
            .withAction('SendEmail')
            .withRegion('eu-central-1')
            .withArgs(args)
            .build()

        const result = await this.aws.execute(request)

        return result && !!result.MessageId
    }

    async uploadFile(path, type, content, properties) {
        let Body = content
        let Metadata = {}

        if (properties) {
            for (const propKey in properties) {
                if (/^([a-z_]+)$/.test(propKey) === false) {
                    throw new Error(
                        `Only lowercase letters and underscores allowed for file properties but found "${propKey}".`
                    )
                }

                if (typeof properties[propKey] !== 'string') {
                    throw new Error(
                        `Only string values for file properties allowed but found "${propKey}=${properties[propKey]}".`
                    )
                }

                Metadata[propKey] = properties[propKey]
            }
        }

        if (content instanceof Blob) {
            Body = await new Promise(resolve => {
                const fileReader = new FileReader()
                fileReader.onload = evt => resolve(new BSON.Binary(new Uint8Array(evt.target.result), 0))
                fileReader.readAsArrayBuffer(content)
            })
            Metadata = { ...Metadata, __binary: 'yes' }
        } else if (content instanceof ArrayBuffer) {
            Body = new BSON.Binary(new Uint8Array(content), 0)
            Metadata = { ...Metadata, __binary: 'yes' }
        }

        const args = {
            Bucket: Config.bucketName,
            ContentType: type,
            Key: path,
            Body,
            Metadata,
        }

        const request = new AwsRequest.Builder()
            .withService('s3')
            .withAction('PutObject')
            .withRegion('eu-central-1')
            .withArgs(args)

        const result = await this.aws.execute(request.build())

        return result && !!result.ETag
    }

    async downloadFile(path) {
        const args = {
            Bucket: Config.bucketName,
            Key: path,
        }

        const request = new AwsRequest.Builder()
            .withService('s3')
            .withAction('GetObject')
            .withRegion('eu-central-1')
            .withArgs(args)

        const result = await this.aws.execute(request.build())
        if (result && result.Body && result.ContentType && result.LastModified) {
            const properties = {}
            let __binary = false

            if (result.Metadata) {
                for (const metaKey in result.Metadata) {
                    const value = result.Metadata[metaKey]

                    if (metaKey === '__binary') {
                        __binary = true
                        continue
                    }

                    properties[metaKey.toLowerCase()] = value
                }
            }

            return {
                type: result.ContentType,
                content: new BSON.Binary(result.Body.buffer, result.Body.sub_type).value(__binary),
                properties,
                modified: new Date(result.LastModified),
            }
        }

        return null
    }

    async deleteFile(path) {
        const args = {
            Bucket: Config.bucketName,
            Key: path,
        }

        const request = new AwsRequest.Builder()
            .withService('s3')
            .withAction('DeleteObject')
            .withRegion('eu-central-1')
            .withArgs(args)

        const result = await this.aws.execute(request.build())

        return !!result
    }
}

const ExportStitch = new StitchWrapper()
ExportStitch.BSON = BSON

export default ExportStitch
