if (!process.env.NODE_ENV) {
    throw new Error('Missing process.env.NODE_ENV')
}

const config = {
    appVersion: process.env.REACT_APP_VERSION,
    appId: process.env.NODE_ENV === 'test' ? process.env.REACT_TEST_APP_ID : process.env.REACT_APP_ID,
    bucketName:
        process.env.NODE_ENV === 'test' ? 'campai-one-test' : process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    dbName: process.env.NODE_ENV === 'test' ? 'test' : 'main',
}

if (!config.appId) {
    throw new Error('Missing config.appId')
}

if (process.env.NODE_ENV !== 'test') {
    if (!config.appVersion) {
        throw new Error('Missing config.appVersion')
    }

    console.info(
        `Running [${process.env.NODE_ENV}] on app-id [${config.appId}] and app-version [${config.appVersion}].`
    )
}

export default config
