import calculateResizeWidth from './calculateResizeWidth'

export default function resizeImage(data, { maxWidth, maxHeight, jpeg, compress } = {}) {
    if (!maxWidth || !maxHeight) {
        throw new Error('maxWidth and maxHeight required')
    }

    const img = new Image()
    const dpi = 1

    return new Promise((resolve, reject) => {
        img.onload = () => {
            const { width, height } = img
            const { width: resizeWidth, height: resizeHeight } = calculateResizeWidth({
                width,
                height,
                maxWidth,
                maxHeight,
            })

            // eslint-disable-next-line no-undef
            const elem = document.createElement('canvas')
            elem.width = resizeWidth * dpi
            elem.height = resizeHeight * dpi
            const ctx = elem.getContext('2d')
            ctx.scale(dpi, dpi)
            ctx.drawImage(img, 0, 0, resizeWidth, resizeHeight)

            const imageType = jpeg ? 'image/jpeg' : 'image/png'

            ctx.canvas.toBlob(
                blob => {
                    resolve({ blob, mimeType: imageType, width: resizeWidth, height: resizeHeight })
                },
                imageType,
                compress ? 0.75 : 1.0
            )
        }

        img.onerror = error => reject(error)
        img.src = data
    })
}
