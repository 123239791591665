import Model from './Model'
import Resource from './Resource'
import ValidationError from './ValidationError'
import declareModel from './declareModel'
import stringSearchTerm from './stringSearchTerm'
import convertFilterToQuery from './convertFilterToQuery'

Model.declare = declareModel
Model.Resource = Resource
Model.ValidationError = ValidationError
Model.stringSearchTerm = stringSearchTerm
Model.convertFilterToQuery = convertFilterToQuery

export default Model
