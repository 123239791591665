import Model from './Model'
import Query from './Query'
import decorateModel from './decorateModel'

export default function declareModel(modelClass) {
    if (!Model.isRootModel(modelClass)) {
        throw new Error(`Invalid Model ${modelClass.name} Can only declare root models.`)
    }

    // Enhance with some static functions bound to the model class
    modelClass.createQuery = (...queryArgs) => new Query(modelClass, ...queryArgs)

    // Decorate it now with mobx
    decorateModel(modelClass)

    return modelClass
}
