import equal from 'fast-deep-equal'

import Model from './Model'
import Contact from './Contact'
import Organisation from './Organisation'

import i18n from '../i18n'

class ContactSegment extends Model {
    static CollectionName = 'contactSegments'

    static Permissions = {
        read: true,
        write: 'contact.edit',
        delete: 'contact.edit',
        insert: 'contact.edit',
    }

    static Fields = {
        organisation: {
            type: Organisation,
            required: true,
        },
        // If set this is only shown for the current user
        userId: {
            type: String,
        },
        key: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            enum: Contact.Fields.type.enum,
            required: true,
        },
        filter: {
            type: Object,
        },
        columnsState: {
            type: [Object],
        },
    }

    constructor(data) {
        super(ContactSegment.Fields, data)
    }

    get isBuiltinSegment() {
        return !this.organisation && !this.user
    }

    areSameSegments(otherSegment) {
        return (
            equal(this.type, otherSegment.type) &&
            equal(this.filter, otherSegment.filter) &&
            equal(this.columnsState, otherSegment.columnsState)
        )
    }
}

export const getAvailableContactSegments = async (organisation, activeOrganisationUser) => {
    const curstomContactSegments = await ContactSegment.createQuery(
        {
            organisation: organisation._id,
            $or: [{ userId: null }, { userId: activeOrganisationUser.userId }],
        },
        {
            createdAt: 1,
        }
    ).execute()

    return curstomContactSegments
}

export const createDefaultContactSegments = () => [
    new ContactSegment({
        key: 'activeMembers',
        name: i18n().contactSegment.defaults.activeMembers,
        type: 'member',
        filter: {
            operator: 'and',
            groups: [
                {
                    operator: 'or',
                    fields: [
                        {
                            field: 'membership.leaveDate',
                            compare: 'date.notExists',
                        },
                        {
                            field: 'membership.leaveDate',
                            compare: 'date.moment',
                            value: 'afterOrAtToday',
                        },
                    ],
                },
            ],
        },
    }),

    new ContactSegment({
        key: 'resignedMembers',
        name: i18n().contactSegment.defaults.resignedMembers,
        type: 'member',
        filter: {
            operator: 'and',
            groups: [
                {
                    operator: 'and',
                    fields: [
                        {
                            field: 'membership.leaveDate',
                            compare: 'date.moment',
                            value: 'beforeOrAtToday',
                        },
                    ],
                },
            ],
        },
    }),

    new ContactSegment({
        key: 'allContacts',
        name: i18n().contactSegment.defaults.allContacts,
        type: 'contact',
    }),
]

export default Model.declare(ContactSegment)
