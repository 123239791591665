export default function getImageSize(data) {
    // eslint-disable-next-line no-undef
    const img = new Image()

    return new Promise((resolve, reject) => {
        img.onload = () => {
            const { width, height } = img
            resolve({ width, height })
        }

        img.onerror = error => reject(error)

        img.src = data
    })
}
