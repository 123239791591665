import uuidv4 from 'uuid/v4'

import Model from './Model'

import decorateModel from './decorateModel'

import Stitch from '../../utils/Stitch'

import getImageSize from '../../utils/graphics/getImageSize'
import resizeImage from '../../utils/graphics/resizeImage'
import getVideoPreview from '../../utils/graphics/getVideoPreview'

const PREVIEW_SIZE = 512

export default class Resource extends Model {
    // -- required to test for resource and avoiding circular deps in Model
    static IS_RESOURCE = true

    static Fields = {
        // -- only used in triggers for cleaning up --
        isResourceModel: {
            type: Boolean,
            default: true,
        },
        type: {
            type: String,
        },
        meta: {
            type: Object,
            default: () => {},
        },
        path: {
            type: String,
        },
        previewPath: {
            type: String,
        },
    }

    static Types = {
        image: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
        video: [
            'video/x-flv',
            'video/flv',
            'video/mp4',
            'application/x-mpegURL',
            'video/MP2T',
            'video/3gpp',
            'video/x-msvideo',
            'video/x-ms-wmv',
            'video/quicktime',
        ],
    }

    static getType(mimeType) {
        for (const type in Resource.Types) {
            if (Resource.Types[type].indexOf(mimeType) >= 0) {
                return type
            }
        }

        return 'file'
    }

    settings = null

    uploadData = null

    constructor(settings) {
        super(Resource.Fields)
        this.settings = settings || null
    }

    get url() {
        if (this.uploadData) {
            return this.uploadData.url || null
        }

        return this.path ? Stitch.getStorageUrl(this.path) : null
    }

    get previewUrl() {
        if (this.uploadData) {
            return this.uploadData.previewUrl || null
        }

        return this.previewPath ? Stitch.getStorageUrl(this.previewPath) : null
    }

    fromResource(resource) {
        this.type = resource.type
        this.meta = { ...resource.meta }
        this.path = resource.path
        this.previewPath = resource.previewPath

        this.uploadData = resource.uploadData
    }

    async fromBlob(blobOrFile, { forceBinaryFile } = {}) {
        if (!(blobOrFile instanceof File) && !(blobOrFile instanceof Blob)) {
            throw new Error('Expected File or Blob')
        }
        if (!blobOrFile.name) {
            throw new Error('Missing name for blob')
        }
        if (!blobOrFile.type) {
            throw new Error('Missing type for blob')
        }

        const getDataUrl = input =>
            new Promise(resolve => {
                const reader = new FileReader()
                reader.onload = evt => resolve(evt.target.result)
                reader.readAsDataURL(input)
            })

        const uploadData = {
            data: blobOrFile,
            filename: blobOrFile.name,
        }

        const type = (!forceBinaryFile && blobOrFile.type) || 'application/octet-stream'
        let meta = {}

        const dataType = Resource.getType(type)
        const dataTypeSettings = this.settings && this.settings[dataType]
        const doPreview = this.settings && this.settings.preview

        if (dataType === 'image') {
            const dataUrl = await getDataUrl(blobOrFile)
            uploadData.url = dataUrl

            if (doPreview) {
                const result = await resizeImage(dataUrl, {
                    maxWidth: PREVIEW_SIZE,
                    maxHeight: PREVIEW_SIZE,
                    jpeg: type === 'image/jpeg',
                    compress: true,
                })

                uploadData.previewData = result.blob
                uploadData.previewType = result.mimeType
                uploadData.previewUrl = await getDataUrl(result.blob)
            }

            if (dataTypeSettings) {
                const result = await resizeImage(dataUrl, {
                    ...dataTypeSettings,
                    jpeg: type === 'image/jpeg',
                    compress: true,
                })

                meta = { ...meta, ...{ width: result.width, height: result.height } }
                uploadData.data = result.blob
            } else {
                const result = await getImageSize(dataUrl)

                meta = { ...meta, ...{ width: result.width, height: result.height } }
            }
        }

        if (dataType === 'video' && doPreview) {
            const dataUrl = await getDataUrl(blobOrFile)

            const result = await getVideoPreview(dataUrl, type, {
                maxWidth: PREVIEW_SIZE,
                maxHeight: PREVIEW_SIZE,
                compress: true,
            })

            if (result) {
                const { width, height, length, previewBlob, previewMimeType } = result
                meta = { ...meta, width, height, length }

                uploadData.previewData = previewBlob
                uploadData.previewType = previewMimeType
                uploadData.previewUrl = await getDataUrl(previewBlob)
            }
        }

        this.type = type
        this.meta = meta
        this.uploadData = uploadData

        return true
    }

    get isStorable() {
        return !!this.uploadData
    }

    async store(path) {
        if (!path) {
            throw new Error('Missing path for resource.')
        }

        const normalizedPath = path.charAt(0) === '/' ? path.substr(1) : path

        if (normalizedPath.charAt(normalizedPath.length - 1) === '/') {
            throw new Error('Unexpected ending slash in resource path.')
        }

        if (this.path && this.path.indexOf(normalizedPath) < 0) {
            throw new Error(`Path missmatch from "${this.path}" to "${normalizedPath}"`)
        }

        if (!this.uploadData) {
            // Nothing to upload so return early
            return true
        }

        // If we have valid data stored on this resource delete it first
        await this._deleteFromStorage()

        // Now upload new data
        const { filename, data, previewData, previewType } = this.uploadData
        const previewFileExtension = previewType === 'image/jpeg' ? '.jpg' : '.png'

        let fileExtension = ''
        if (filename) {
            const ext = /^.+\.([^.]+)$/.exec(filename)
            fileExtension = ext == null ? '' : `.${ext[1]}`
        }

        const fileId = this.settings && this.settings.key ? this.settings.key : uuidv4()
        this.path = `${normalizedPath}/${fileId}${fileExtension}`
        this.previewPath = previewData ? `${normalizedPath}/${fileId}-preview${previewFileExtension}` : null

        const [r1, r2] = await Promise.all([
            Stitch.uploadFile(this.path, this.type, data),
            previewData && previewType
                ? Stitch.uploadFile(this.previewPath, previewType, previewData)
                : Promise.resolve(true),
        ])

        this.uploadData = null

        return r1 && r2
    }

    async delete() {
        const result = await this._deleteFromStorage()

        this.type = null
        this.meta = {}
        this.path = null
        this.previewPath = null
        this.uploadData = null

        return result
    }

    async _deleteFromStorage() {
        if (this.path) {
            const [r1, r2] = await Promise.all([
                Stitch.deleteFile(this.path),
                this.previewPath ? Stitch.deleteFile(this.previewPath) : Promise.resolve(true),
            ])

            if (!r1 || !r2) {
                return false
            }
        }
        return true
    }
}

decorateModel(Resource)
