import React from 'react'
import { observer } from 'mobx-react-lite'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined'
import { makeStyles } from '@material-ui/core/styles'

import i18n from '../../../../i18n'

import zone from '../../../../utils/zone'

const useStyles = makeStyles(() => ({
    iconButton: {
        '& .MuiIconButton-label': {
            width: 32,
            height: 32,
        },
    },
}))

export default observer(() => {
    const classes = useStyles()

    if (!zone.intercom) {
        return null
    }

    const handleClick = () => {
        window.Intercom('show')
    }

    return (
        <Tooltip title={i18n().navigation.support}>
            <IconButton className={classes.iconButton} size="small" onClick={handleClick}>
                <LiveHelpOutlinedIcon />
            </IconButton>
        </Tooltip>
    )
})
