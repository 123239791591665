import { decorate, observable } from 'mobx'

import Stitch from '../../utils/Stitch'

import { aquireWatcher, releaseWatcher } from './watchers'
import convertUpdateDocument from './convertUpdateDocument'

export default class Query {
    modelClass

    query

    sort

    documents = null

    count = null

    loading = false

    _watcher = null

    constructor(modelClass, query, sort, { allowRemoved = false, ignoreTestUUID = false } = {}) {
        if (!query || typeof query !== 'object') {
            throw new Error('Invalid query parameter.')
        }

        this.modelClass = modelClass
        this.query = {
            ...Stitch.BSON.deserialize(Stitch.BSON.serialize(query)),
            removedAt: { $exists: false },
        }

        if (allowRemoved) {
            delete this.query.removedAt
        } else {
            this.query.removedAt = { $exists: false }
        }

        this.sort = sort

        if (!this.sort || !Object.keys(this.sort).length) {
            this.sort = { _id: -1 }
        }

        // Ugly hack - in test mode we add an "invisible" uuid to the query
        // so we hopefully don't clanch with other tests running in parallel
        if (process.env.NODE_ENV === 'test' && !ignoreTestUUID) {
            this.query.__testUUID = process.env.MODEL_TEST_UUID
        }
    }

    getDocumentById(documentId) {
        if (this.documents) {
            return this.documents.find(document => document.id === documentId.toString())
        }
        return null
    }

    clone(query, sort, options) {
        const newSort = sort || this.sort

        const { removedAt, ...myQuery } = this.query

        return new Query(
            this.modelClass,
            { ...query, ...Stitch.BSON.deserialize(Stitch.BSON.serialize(myQuery)) },
            newSort,
            options
        )
    }

    get collection() {
        return Stitch.db.collection(this.modelClass.CollectionName)
    }

    async getCount() {
        this.count = await this.collection.count(this.query)

        return this.count
    }

    async load(limit = 100, skip, ignoreLoading = false) {
        if (!ignoreLoading) {
            this.loading = true
        }

        const countAndQueryPromises = []

        // If we don't have a totalCount then get it with the first request
        if (typeof this.count !== 'number') {
            countAndQueryPromises.push(this.getCount())
        } else {
            // Add a dummy promise to resolve to our current count
            countAndQueryPromises.push(Promise.resolve(this.count))
        }

        const useSkip = typeof skip === 'number' ? skip : this.documents ? this.documents.length : 0
        const useLimit = limit || 100

        countAndQueryPromises.push(this.execute(useLimit, useSkip))

        const [, loadedDocuments] = await Promise.all(countAndQueryPromises)

        if ((!this.documents && useSkip === 0) || (this.documents && useSkip === this.documents.length)) {
            // we can safely simply append the loaded documents
            this.documents = (this.documents || []).concat(loadedDocuments)
        } else {
            // We are utilizing sparse arrays to insert as skip might have left
            // out some rows. To do this we first clone the original array and
            // assign a copy back as otherwise mobx would go crazy (out of bounds shit)
            const newDocuments = (this.documents || []).slice()

            for (let index = useSkip; index < useSkip + loadedDocuments.length; index += 1) {
                newDocuments[index] = loadedDocuments[index - useSkip]
            }

            this.documents = newDocuments
        }

        if (!ignoreLoading) {
            this.loading = false
        }

        return loadedDocuments
    }

    async execute(limit = 100, skip) {
        const useSkip = skip || 0
        const useLimit = limit || 100

        let documents

        // TODO : Fix this. Right now we use aggregate in case
        // we need to skip but thats no good for performance. We
        // should enable proper range limits via _id or so (see mongo cursor)
        // but it needs to work correctly with multiple sorts set and so on.
        if (useSkip) {
            documents = await this.collection
                .aggregate([
                    { $match: this.query },
                    { $sort: this.sort },
                    { $skip: useSkip },
                    { $limit: useLimit },
                ])
                .toArray()
        } else {
            documents = await this.collection
                .find(this.query, {
                    sort: this.sort,
                    limit: useLimit,
                })
                .toArray()
        }

        return (documents || []).map(document => {
            return this._createModelFromDocument(document)
        })
    }

    async group(groups, limit = 100, skip) {
        const useSkip = skip || 0
        const useLimit = limit || 100

        const $group = {
            _id: {},
            count: { $sum: 1 },
            sort_id: { $first: '$_id' },
        }
        const $sort = {}

        const propertiesKeyMap = {}

        for (const groupKey in groups) {
            const groupInfo = groups[groupKey]

            // Normalize nested group keys so we can use them and later destructure them again
            const normalizedGroupKey = groupKey.replace('.', '___')
            propertiesKeyMap[normalizedGroupKey] = groupKey

            if (groupInfo.mode) {
                $group[normalizedGroupKey] = { [`$${groupInfo.mode}`]: `$${groupKey}` }
            } else {
                $group._id[normalizedGroupKey] = `$${groupKey}`

                if (groupInfo.sort) {
                    $sort[`_id.${normalizedGroupKey}`] = groupInfo.sort
                }
            }
        }

        const basePipelines = [{ $match: this.query }, { $group }]

        basePipelines.push({
            $sort: {
                ...$sort,
                // we always need to sort by a stable sort_id last as otherwise sort order might be unpredictable
                '_id.a_sort_id': 1,
            },
        })

        const groupPipelines = basePipelines.concat([{ $skip: useSkip }, { $limit: useLimit }])
        const groupPromise = this.collection.aggregate(groupPipelines).toArray()
        let groupsCount
        let groupedResults

        // If we don't skip anything we'll also make a separate call to get the total count
        // of all available groups for proper pagination
        if (useSkip === 0) {
            const getGroupsGrount = async () => {
                const result = await this.collection
                    .aggregate(basePipelines.concat([{ $count: 'groupsCount' }]))
                    .toArray()

                if (!result || result.length !== 1 || !result[0].groupsCount) {
                    return -1 // indicate an error
                }
                return result[0].groupsCount
            }

            ;[groupsCount, groupedResults] = await Promise.all([getGroupsGrount(), groupPromise])
        } else {
            groupedResults = await groupPromise
        }

        const assignResultProperty = (property, value, target) => {
            const originalProperty = propertiesKeyMap[property]
            target[originalProperty] = value
        }

        const result = {
            groups: groupedResults.map(groupedResult => {
                // ignore sort_id its just a helper and merge _id results into root
                const { _id: groupProperties, sort_id, count, ...aggProperties } = groupedResult

                const groupResult = { count }

                for (const groupProperty in groupProperties) {
                    assignResultProperty(groupProperty, groupProperties[groupProperty], groupResult)
                }

                for (const aggProperty in aggProperties) {
                    assignResultProperty(aggProperty, aggProperties[aggProperty], groupResult)
                }

                return groupResult
            }),
            totalCount: groupedResults.reduce(
                (totalCount, groupedResult) => totalCount + groupedResult.count,
                0
            ),
        }

        if (typeof groupsCount === 'number') {
            result.groupsCount = groupsCount
        }

        return result
    }

    async loadAll(bulkLimit = 1000) {
        this.loading = true

        const totalCount = await this.getCount()
        if (totalCount >= 5000) {
            throw new Error('More than 5k bulk load is not supported.')
        }

        if (!totalCount) {
            this.loading = false
            return 0
        }

        const pageCount = Math.ceil(totalCount / bulkLimit)
        for (let pageIndex = 0; pageIndex < pageCount; pageIndex += 1) {
            // eslint-disable-next-line no-await-in-loop
            await this.load(bulkLimit, undefined, true)
        }

        this.loading = false

        if (this.documents.length !== totalCount) {
            throw new Error(
                `Error while bulk loading, expected ${totalCount} documents but got only ${this.documents.length} documents.`
            )
        }

        return this.documents.length
    }

    async watch() {
        if (this._watcher) {
            throw new Error(`Already installed a watcher on ${this.modelClass.name} Query.`)
        }

        this._watcher = await aquireWatcher(
            this.collection,
            this.query,
            (operation, document, documentId) => {
                if (operation === 'insert' || operation === 'update' || operation === 'replace') {
                    const existingDocument = this.getDocumentById(documentId)
                    if (existingDocument) {
                        existingDocument.deserialize(document)
                    }

                    if (operation === 'insert' && !existingDocument) {
                        this.documents = this.documents.concat([this._createModelFromDocument(document)])
                    }
                } else if (operation === 'delete') {
                    const existingDocument = this.getDocumentById(documentId)
                    if (existingDocument) {
                        this.documents = this.documents.filter(doc => doc !== existingDocument)
                    }
                }
            }
        )

        return true
    }

    async unwatch() {
        if (this._watcher) {
            releaseWatcher(this._watcher)
            this._watcher = null
        }
    }

    async update(updateData) {
        const updateDocument = convertUpdateDocument(updateData)
        if (!Object.keys(updateDocument).length) {
            // nothing to update so return immediately
            return 0
        }

        const result = await this.collection.updateMany(this.query, updateDocument, { upsert: false })

        return result ? result.modifiedCount : 0
    }

    async remove() {
        const result = await this.collection.updateMany(
            this.query,
            { $set: { removedAt: new Date() } },
            { upsert: false }
        )

        return result ? result.modifiedCount : 0
    }

    async delete() {
        const result = await this.collection.deleteMany(this.query)

        return result ? result.deletedCount : 0
    }

    createModelInstance(...args) {
        const ModelClass = this.modelClass
        return new ModelClass(...args)
    }

    _createModelFromDocument(document) {
        const modelInstance = this.createModelInstance()
        modelInstance.deserialize(document)
        return modelInstance
    }
}

decorate(Query, {
    documents: observable,
    count: observable,
    loading: observable,
})
