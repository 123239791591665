import React, { useContext, useState } from 'react'
import { observer } from 'mobx-react-lite'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

import Avatar from '../../../../components/Avatar'
import Link from '../../../../components/Link'

import AppState from '../../AppState'

const useStyles = makeStyles(theme => ({
    menuItemLabel: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

export default observer(({ navigationState }) => {
    const appState = useContext(AppState)

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)

    const handleToggleMenu = event => {
        setAnchorEl(value => (value ? null : event.currentTarget))
    }

    const userName = appState.userData.name || appState.userData.email

    return (
        <>
            <Tooltip title={userName}>
                <IconButton edge="end" onClick={handleToggleMenu} size="small">
                    <Avatar name={userName} src={appState.userData.avatar.url} size={32} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleToggleMenu}
            >
                {navigationState.userItems.map((navigationItem, itemIndex) => {
                    const handleClick = () => {
                        handleToggleMenu()

                        if (navigationItem.onClick) {
                            navigationItem.onClick()
                        }
                    }

                    return (
                        <MenuItem
                            key={itemIndex}
                            component={Link}
                            href={navigationItem.href}
                            target={navigationItem.target}
                            to={typeof navigationItem.route === 'string' ? `${navigationItem.route}` : null}
                            underline="none"
                            onClick={handleClick}
                        >
                            <navigationItem.Icon />
                            <div className={classes.menuItemLabel}>{navigationItem.label}</div>
                        </MenuItem>
                    )
                })}
            </Menu>
        </>
    )
})
