import resizeImage from './resizeImage'

export default async function getVideoPreview(data, mimeType, resizeImageSettings) {
    const video = document.createElement('video')
    if (!video.canPlayType(mimeType)) {
        return null
    }

    try {
        const loadPromises = Promise.all([
            new Promise(resolve => {
                video.onloadedmetadata = () => {
                    resolve({ width: video.videoWidth, height: video.videoHeight })
                }
            }),
            new Promise(resolve => {
                video.onloadeddata = () => {
                    video.currentTime = 0
                    resolve(video.duration)
                }
            }),
            new Promise(resolve => {
                video.onseeked = () => {
                    resolve()
                }
            }),
        ])

        video.src = data
        video.load()

        const [size, length] = await loadPromises

        // eslint-disable-next-line no-undef
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = size.width
        canvas.height = size.height
        ctx.drawImage(video, 0, 0, size.width, size.height)

        const dataUrl = canvas.toDataURL('image/png')
        const previewImage = await resizeImage(dataUrl, { ...resizeImageSettings, jpeg: true })

        return {
            width: size.width,
            height: size.height,
            length,
            previewBlob: previewImage.blob,
            previewMimeType: previewImage.mimeType,
        }
    } catch (e) {
        return null
    }
}
