import { decorate, observable, action, computed } from 'mobx'
import moment from 'moment'

import Organisation from '../../models/Organisation'

import { getAvailableContactTags } from '../../models/ContactTag'
import { getAvailableMemberSections } from '../../models/MemberSection'
import { createDefaultContactSegments, getAvailableContactSegments } from '../../models/ContactSegment'

import i18n from '../../i18n'

import countries from '../../utils/countries'

class AppOrganisation {
    organisation

    user

    _contactTags = []

    _memberSections = []

    contactTagsOptions = []

    memberSectionsOptions = []

    contactSegments = []

    async init(activeOrganisationUser) {
        this.organisation = new Organisation()

        if (!(await this.organisation.load(activeOrganisationUser.organisation))) {
            return false
        }

        i18n.setCountry(countries[this.organisation.country])

        this.user = activeOrganisationUser
        const defaultContactSegments = createDefaultContactSegments()

        const [availableTags, availableSections, availableContactSegments] = await Promise.all([
            getAvailableContactTags(this.organisation),
            getAvailableMemberSections(this.organisation),
            getAvailableContactSegments(this.organisation, this.user),
        ])

        this.contactTags = availableTags
        this.memberSections = availableSections
        this.contactSegments = defaultContactSegments.concat(availableContactSegments)

        // Save last access here but ignore updateBy fields in case of permissions though
        // if this is on the same day as the creation day ignore this so we get more fine
        // grained statistics later on about the "real" access to this organisation.
        if (!moment().isSame(moment(this.organisation.created), 'day')) {
            this.organisation.update(
                { lastAccessedAt: new Date(), lastAccessedBy: activeOrganisationUser.userId },
                true
            )
        }

        // this.floors = Floor.createQuery({ organisation: this.organisation._id })
        // this.buildings = Building.createQuery({ organisation: this.organisation._id })

        // await Promise.all([this.floors.getCount(), this.buildings.getCount()])

        return true
    }

    set contactTags(tags) {
        // Updates options array. Has to replace to not lose pointer
        this.contactTagsOptions.replace(
            tags.map(tag => ({
                value: tag.tag,
                label: tag.tag,
                usageCount: tag.usageCount,
            }))
        )

        this._contactTags = tags

        return tags
    }

    get contactTags() {
        return this._contactTags
    }

    set memberSections(sections) {
        // Updates options array. Has to replace to not lose pointer
        this.memberSectionsOptions.replace(
            sections.map(section => ({
                value: section.section,
                label: section.section,
                usageCount: section.usageCount,
            }))
        )

        this._memberSections = sections

        return sections
    }

    get memberSections() {
        return this._memberSections
    }

    async release() {
        i18n.setCountry(null)
    }

    hasScope(requiredScope) {
        // -- owner always has all scopes
        if (this.user.owner) {
            return true
        }

        for (let scopeIndex = 0; scopeIndex < this.user.scopes.length; scopeIndex += 1) {
            const scope = this.user.scopes[scopeIndex]

            // If user has scope organisation.scope and we require scope organisation
            // then this will also equal to true as we evaluate from left to right
            if (scope.indexOf(requiredScope) === 0) {
                return true
            }
        }

        return false
    }

    removeSegment(segmentToRemove) {
        this.contactSegments.replace(
            this.contactSegments.filter(segment => segment.key !== segmentToRemove.key)
        )
    }
}

decorate(AppOrganisation, {
    organisation: observable,

    contactTags: computed,
    contactTagsOptions: observable,

    memberSections: computed,
    memberSectionsOptions: observable,

    contactSegments: observable,
    updateOrganisationData: action,

    user: observable,
    floors: observable,
    buildings: observable,
    analytics: observable,
})

export default AppOrganisation
