/* eslint-disable max-classes-per-file */
import Model from './Model'
import Organisation from './Organisation'

import Stitch from '../utils/Stitch'

class OrganisationUser extends Model {
    static CollectionName = 'organisationUsers'

    static Permissions = {
        ignoreUserId: true,
        read: 'organisation.view',
        write: 'organisation.edit',
        delete: 'organisation.edit',
        insert: 'organisation.edit',
        fields: ['userId', 'organisation', 'name', 'email', 'owner', 'avatarPath'],
    }

    static Fields = {
        organisation: {
            type: Organisation,
            required: true,
        },
        userId: {
            type: String,
            required: doc => !doc.invitationToken,
        },
        email: {
            type: String,
            format: 'email',
            required: true,
        },
        name: {
            type: String,
        },
        avatarPath: {
            type: String,
        },
        invitationToken: {
            type: String,
            required: doc => !doc.userId,
        },
        // If set can do everything. Only owners can make owners. In
        // this case, all scopes are ignored (aka full-access)
        owner: {
            type: Boolean,
            default: false,
        },
        scopes: {
            type: [String],
        },
    }

    constructor(data) {
        super(OrganisationUser.Fields, data)
    }

    get invitationLink() {
        if (!this.invitationToken) {
            return null
        }

        return `${window.location.protocol}//${window.location.host}/?invitationToken=${this.invitationToken}`
    }

    get avatarUrl() {
        return this.avatarPath ? Stitch.getStorageUrl(this.avatarPath) : null
    }
}

export default Model.declare(OrganisationUser)
