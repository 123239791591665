import moment from 'moment'

export default date => {
    const dateMoment = moment(date)

    const dayOfYear = dateMoment.dayOfYear()
    const isLeapYear = dateMoment.isLeapYear()

    // Leap year is the default
    if (isLeapYear) {
        return dayOfYear
    }

    const year = dateMoment.year()

    const leapDayOfYear = moment(`${year}-03-01T00:00:00+01:00`)

    // If the day in non-leap-year is after the leap year day, add 1
    if (dateMoment.isAfter(leapDayOfYear)) {
        return dayOfYear + 1
    }

    // Return the default since it is same or before 28.02
    return dayOfYear
}
