import React from 'react'
import { observer } from 'mobx-react-lite'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'

import zone from '../../../../utils/zone'

import NavigationItem from '../NavigationItem'

import UserNavigation from './UserNavigation'

const drawerWidth = 280

const useStyles = makeStyles(theme => ({
    drawerRoot: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        background: theme.palette.background.sidebar,
        width: drawerWidth,
        border: '0px solid transparent',
        zIndex: 0,
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(3),
    },
}))

export default observer(({ navigationState }) => {
    const classes = useStyles()
    return (
        <Drawer
            variant="permanent"
            open
            classes={{
                root: classes.drawerRoot,
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.logo}>
                <img src={zone.icon} width={50} alt={zone.name} />
            </div>
            <UserNavigation navigationState={navigationState} />
            <List component="div">
                {navigationState.mainItems.map((navigationItem, index) => (
                    <NavigationItem key={`main-${index}`} navigationItem={navigationItem} />
                ))}
            </List>
        </Drawer>
    )
})
