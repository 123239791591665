import React from 'react'
import { observer } from 'mobx-react-lite'
import IconButton from '@material-ui/core/IconButton'

import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/core/styles'

import Link from '../../../../components/Link'

import zone from '../../../../utils/zone'

const useStyles = makeStyles(theme => ({
    toolbar: {
        minHeight: '56px !important',
    },
    title: {
        marginRight: theme.spacing(1),
        color: 'inherit',
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
}))

export default observer(({ onToggleDrawer }) => {
    const classes = useStyles()

    return (
        <Toolbar className={classes.toolbar}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={onToggleDrawer}
                className={classes.menuButton}
            >
                <MenuIcon />
            </IconButton>
            <Link href="/" variant="h6" underline="none" color="textPrimary" className={classes.title}>
                {zone.name}
            </Link>
        </Toolbar>
    )
})
