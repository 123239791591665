/* eslint-disable max-len  */
/* eslint-disable no-template-curly-in-string */

export default {
    misc: {
        number: {
            delimiters: {
                thousands: '.',
                decimal: ',',
            },
        },
        yesNo: {
            yes: 'Ja',
            no: 'Nein',
        },
        errors: {
            unknown: 'Ein unbekannter Fehler ist aufgetreten, bitte kontaktiere den Support.',
        },
        close: 'Schließen',
    },
    mail: {
        inviteUser: {
            subject: 'Einladung zu ${this.organisationName}',
            text:
                'Du wurdest zur Organisation "${this.organisationName}" bei ${this.appName} eingeladen. Folge dem Link um die Einladung anzunehmen:\n\n${this.invitationLink}',
        },
    },
    dataFilter: {
        addFilter: 'Filter hinzufügen',
        addFilterToGroup: 'Filter zu Gruppe hinzufügen',
        removeFilter: 'Filter entfernen',
        missingValue: 'fehlt ein Wert',
        done: 'Erledigt',
        operator: {
            and: 'und',
            or: 'oder',
        },
        compare: {
            list: {
                contains: 'enthält',
                notContains: 'enthält nicht',
                empty: 'ist leer',
                notEmpty: 'ist nicht leer',
            },
            string: {
                equals: 'ist',
                notEquals: 'ist nicht',
                contains: 'enthält',
                notContains: 'enthält nicht',
                startsWith: 'beginnt mit',
                endsWith: 'endet mit',
                exists: 'hat irgendeinen Wert',
                notExists: 'hat keinen Wert',
            },
            number: {
                equals: 'ist',
                notEquals: 'ist nicht',
                greaterThan: 'größer als',
                lessThan: 'kleiner als',
                exists: 'hat irgendeinen Wert',
                notExists: 'hat keinen Wert',
            },
            date: {
                moreThanDaysAgo: 'vor mehr als',
                exactlyDaysAgo: 'vor exakt',
                lessThanDaysAgo: 'vor weniger als',
                moment: '',
                after: 'nach dem',
                equals: 'am',
                before: 'vor dem',
                exists: 'hat irgendeinen Wert',
                notExists: 'hat keinen Wert',
                groupLabels: {
                    relative: 'Relativ',
                    custom: 'Eigene',
                    absolute: 'Absolut',
                },
                postfixLabels: {
                    days: 'Tage(n)',
                },
                momentValues: {
                    beforeToday: 'Vor heute',
                    beforeOrAtToday: 'Heute oder davor',
                    afterToday: 'Nach heute',
                    afterOrAtToday: 'Heute oder danach',
                },
            },
            bool: {
                isTrue: 'Ist wahr',
                isFalse: 'Ist falsch',
                exists: 'hat irgendeinen Wert',
                notExists: 'hat keinen Wert',
            },
        },
    },
    dataGrid: {
        enabled: 'Aktiviert',
        pinColumn: 'Spalte anheften',
        valueAggregation: 'Aggregieren',
        autosizeThiscolumn: 'Breite an Inhalt anpassen',
        autosizeAllColumns: 'Alle Spalten an Inhalt anpassen',
        groupBy: 'Gruppieren nach',
        ungroupBy: 'Ungruppieren von',
        resetColumns: 'Alle Spalten zurücksetzen',
        expandAll: 'Alles aufklappen',
        collapseAll: 'Alles zuklappen',
        pinLeft: 'Links anheften',
        pinRight: 'Rechts anheften',
        noPin: 'Nicht anheften',
        emptyGroup: 'nicht gesetzt',
    },
    navigation: {
        start: 'Startseite',
        contacts: 'Mitglieder',
        finances: 'Finanzen',
        accounting: 'Buchhaltung',
        settings: 'Einstellungen',
        updates: 'Neueste Produkt Updates',
        feedback: 'Feature oder Bug melden',
        help: 'Hilfecenter',
        support: 'Support anfragen',
        userNotifications: '${this.count} Benachrichtigungen',
        noneCount: 'Keine',
        userSettings: 'Meine Einstellungen',
        userSignOut: 'Abmelden',
    },
    auth: {
        signUp: {
            title: 'Konto erstellen',
            action: 'Registrieren',
            email: 'Deine E-Mail Adresse',
            password: 'Wähle eine Passwort',
            passwordRepeat: 'Wiederhole dein Passwort',
            alreadyRegisteredLogin: 'Schon registriert? Hier anmelden',
            errors: {
                invalidEmail: 'Ungültige E-Mail Adresse',
                invalidPassword: 'Ungültiges Passwort, mindestens sechs Zeichen.',
                passwordMissmatch: 'Die beiden Passwörter stimmen nicht überein.',
                alreadySignedUp: 'Ein Konto existiert bereits. Logge dich mit deinen Zugangsdaten ein.',
            },
        },
        login: {
            title: 'Login',
            action: 'Einloggen',
            email: 'Registrierte E-Mail Adresse',
            password: 'Passwort',
            passwordForgotten: 'Passwort vergessen?',
            noAccountSignUp: 'Kein Konto? Kostenlos registrieren.',
            errors: {
                invalidCredentials: 'Ungültige E-Mail Adresse oder Passwort',
            },
        },
        resetPassword: {
            title: 'Passwort zurücksetzen',
            action: 'Mail zum Zurücksetzen senden',
            email: 'Registrierte E-Mail Adresse',
            success:
                'Eine E-Mail wurde an ${this.email} mit Anweisungen zum zurücksetzen deines Passworts gesendet.',
            errors: {
                invalidEmail: 'Kein Konto mit der E-Mail Adresse gefunden.',
            },
        },
    },
    organisation: {
        name: 'Verein',
        yours: 'Deine Vereine',
        create: {
            label: 'Erstelle einen neuen Verein',
            description:
                'Verwalte deine Mitglieder, ziehe deine Beiträge effizient ein und hab deine Buchhaltung im Griff.',
            name: 'Wie heißt der Verein?',
            country: 'In welchem Land befindet sich der Verein?',
            action: 'Verein anlegen',
        },
    },
    contactSegment: {
        defaults: {
            activeMembers: 'Mitglieder',
            resignedMembers: 'Ehemalige Mitglieder',
            allContacts: 'Kontakte',
        },
        editor: {
            name: 'Name',
            scope: {
                title: 'The segment will be visible:',
                me: 'Only for me',
                organisation: 'To everyone on the organisation',
            },
            confirm: 'Confirm',
        },
    },
    contact: {
        personal: {
            avatar: 'Bild',
            type: 'Art',
            organisationName: 'Organisation',
            personSalutation: 'Anrede',
            personTitle: 'Titel',
            personFirstName: 'Vorname',
            personLastName: 'Nachname',
            personBirthday: 'Geburtstag',
            personAge: 'Alter',
            typeValue: {
                malePerson: 'Herr',
                femalePerson: 'Frau',
                otherPerson: 'Person',
                business: 'Firma',
                club: 'Verein',
                association: 'Verband',
                otherOrganisation: 'Organisation',
            },
        },
        address: {
            name: 'Anschrift',
            address: 'Adresse',
            addressPlaceholder: 'Strasse, PLZ Stadt',
            street: 'Strasse + Nr.',
            zip: 'PLZ',
            city: 'Stadt',
            country: 'Land',
            noValidAddress: 'Keine gültige Adresse',
        },
        communication: {
            name: 'Kommunikation',
            email: 'E-Mail',
            mobilePhone: 'Handy',
            chapsterSync: 'Chapster',
            mayContact: 'Erlaubt',
            status: {
                notAllowed: 'Kontakt nicht erlaubt',
                noContact: 'Keine Kontaktdaten',
            },
        },
        note: {
            createdAt: 'Erstellt',
            createdBy: 'Von',
            followUpAt: 'Wiedervorlage',
            content: 'Notiz',
        },
        membership: {
            name: 'Mitgliedschaft',
            status: {
                type: {
                    willEnter: 'Wird eintreten',
                    willLeave: 'Wird austreten',
                    hasLeft: 'Ausgetreten',
                    isActive: 'Mitglied',
                },
                value: {
                    willEnter: 'Eintritt am',
                    willLeave: 'Wird austreten am',
                    hasLeft: 'Ausgetreten am',
                    isActive: 'Mitglied seit',
                },
            },
            number: 'Mitgliedsnummer',
            numberShort: 'Nr.',
            enterDate: 'Eintritt',
            leaveDate: 'Austritt',
            sections: 'Abteilungen',
        },
        tags: 'Tags',
        balance: 'Konto',
        typeValue: {
            contact: {
                single: 'Kontakt',
                multi: 'Kontakte',
            },
            member: {
                single: 'Mitglied',
                multi: 'Mitglieder',
            },
        },
    },
    contacts: {
        create: {
            contact: 'Neuer Kontakt',
            member: 'Neues Mitglied',
        },
        search: 'Name, Mitglieds-Nr.',
        saveSegment: 'Ansicht speichern',
        editSegment: 'Edit segment',
        deleteSegment: 'Delete segment',
    },
}
