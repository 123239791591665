const selectedColor = 'rgba(21, 171, 246, 0.25)'
const primaryMainColor = '#15ABF6'

export default () => ({
    palette: {
        primary: {
            main: primaryMainColor,
            dark: '#017EBD',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#CB0676',
            contrastText: '#ffffff',
        },
        error: {
            main: '#FF6666',
        },
        warning: {
            main: '#FFCC00',
        },
        info: {
            main: '#0EA69C',
        },
        success: {
            main: '#1DBC77',
        },
        divider: 'rgba(0, 0, 0, 0.10)',
        background: {
            default: '#ffffff',
            sidebar: '#fafafa',
        },
        action: {
            selected: selectedColor,
            selectedOpacity: 0.25,
        },
    },
    props: {
        MuiButton: {
            disableElevation: true,
        },
        MuiRadio: {
            disableRipple: true,
        },
        MuiCheckbox: {
            disableElevation: true,
        },
    },
    overrides: {
        MuiButton: {
            contained: {
                transition: 'none',
                backgroundColor: '#ebebeb',
                '&:hover': {
                    backgroundColor: selectedColor,
                },
            },
            outlined: {
                transition: 'none',
                '&:hover': {
                    backgroundColor: 'white',
                    borderColor: primaryMainColor,
                    color: primaryMainColor,
                },
            },
        },
        MuiRadio: {
            root: {
                backgroundColor: 'transparent !important',
                cursor: 'default',
                padding: '5px 9px',
                transition: 'none !important',
                '& .MuiSvgIcon-root': {
                    transition: 'none !important',
                },
            },
        },
        MuiCheckbox: {
            root: {
                backgroundColor: 'transparent !important',
                cursor: 'default',
                padding: '5px 9px',
            },
        },
    },
    typography: {
        htmlFontSize: 14,
        fontSize: 14,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        button: {
            fontSize: '1rem',
        },
        body1: {
            fontSize: '1rem',
        },
    },
})
