import React, { useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import FormControl from '@material-ui/core/FormControl'
import BusinessIcon from '@material-ui/icons/Business'
import { makeStyles } from '@material-ui/core/styles'

import Avatar from '../../../components/Avatar'
import Button from '../../../components/Button'
import TextInput from '../../../components/TextInput'
import ChooseGroup from '../../../components/ChooseGroup'

import Organisation from '../../../models/Organisation'

import zone from '../../../utils/zone'

import countries from '../../../utils/countries'

import i18n from '../../../i18n'

const useStyles = makeStyles(theme => ({
    title: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 18,
        fontWeight: 500,
        marginBottom: theme.spacing(2),
    },
    typeAvatar: {
        background: `${theme.palette.primary.main} !important`,
        marginRight: theme.spacing(1),
    },
    description: {
        fontSize: 16,
        color: theme.palette.text.secondary,
        textAlign: 'center',
        marginBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    form: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}))

export default observer(() => {
    const classes = useStyles()

    const [organisation] = useState(new Organisation({ name: '', country: zone.defaultCountry }))

    const [isSaving, setSaving] = useState(false)

    const handleChangeCountry = country => {
        organisation.country = country
    }

    const handleChangeName = name => {
        organisation.name = name
    }

    const handleSubmit = async evt => {
        evt.preventDefault()

        setSaving(true)
        const result = await organisation.create()

        setSaving(false)

        if (!result) {
            alert('Error')
            return false
        }

        // enforce reload to gather the organisation
        window.location.href = `/${organisation.id}`

        return false
    }

    const countryOptions = useMemo(() => {
        return zone.countries.map(countryCode => ({
            value: countryCode,
            label: countries.getCountryName(countryCode),
        }))
    }, [])

    return (
        <div>
            <div className={classes.title}>
                <Avatar className={classes.typeAvatar}>
                    <BusinessIcon />
                </Avatar>
                {i18n().organisation.create.label}
            </div>
            <div className={classes.description}>{i18n().organisation.create.description}</div>
            <form className={classes.form} onSubmit={handleSubmit}>
                <TextInput
                    fullWidth
                    margin="normal"
                    label={i18n().organisation.create.name}
                    value={organisation.name}
                    onChange={handleChangeName}
                    autoFocus
                    required
                />
                {countryOptions.length > 1 && (
                    <ChooseGroup
                        margin="normal"
                        label={i18n().organisation.create.country}
                        value={organisation.country}
                        onChange={handleChangeCountry}
                        options={countryOptions}
                        error
                    />
                )}
                <FormControl margin="normal" fullWidth>
                    <Button
                        fullWidth
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                        disabled={!organisation.isValid}
                        loading={isSaving}
                    >
                        {i18n().organisation.create.action}
                    </Button>
                </FormControl>
            </form>
        </div>
    )
})
